interface ErrorMessageProps {
  error: Error | null;
}

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  return (
    <div style={{ padding: 40 }}>
      <h1>Something went wrong.</h1>
      <h3>Try refreshing the page.</h3>
      {error && import.meta.env.NODE_ENV !== 'production' && error.message && <p>{error.message}</p>}
      <h3>If the problem persists, please contact support.</h3>
    </div>
  );
};
