import { modules } from '@web/constants/intacct';

const opCodes = {
  pa: {
    timesheet: '4852',
    staff: '4810',
  },
  ee: {
    timesheet: '4892',
    staff: '4887',
  },
} as const;

/*
 * Use `useSendPrintToIntacct` hook to send print request to Intacct instead of using this directly
 * to ensure that the timesheet is saved before sending the print request.
 *
 * @param intacctTimesheetRecordNo Timesheet record number in Intacct
 */
export function sendPrintToIntacct(
  intacctTimesheetRecordNo: string | number,
  mod: typeof modules.pa | typeof modules.ee,
  isStaff: boolean,
) {
  const opCode = opCodes[mod][isStaff ? 'staff' : 'timesheet'];
  window.parent.postMessage(
    JSON.stringify({
      function: 'open_pdf_pop_up',
      p_op: opCode, // op-code for timesheet. You can find this by inspecting HTML on Intacct.
      p_it: 'timesheet',
      p_r: `${intacctTimesheetRecordNo}`,
      p_mod: mod,
      p_title: 'Timesheet',
    }),
    // In the future, we can consider using an environment variable to validate the referrer.
    // However, this isn't sensitive info, so it's not a high priority.
    document.referrer || '*',
  );
}

/**
 * @param recordNo Record number in Intacct resource including timesheet.
 */
export function sendOpenAuditPopup(recordNo: string | number) {
  window.parent.postMessage(
    JSON.stringify({
      function: 'open_audit_pop_up',
      p_it: 'timesheet',
      p_r: `${recordNo}`,
    }),
    document.referrer || '*',
  );
}
