import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import ErrorBoundary from '@web/components/ErrorBoundary';
import { PageContent } from '@web/components/Page';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import DashboardContainer from '../DashboardContainer';
import { ErrorFallback } from './Error';
import { RetentionModalContext, useRetentionModalProvider } from './hooks/useRetentionModal';
import RetentionModal from './RetentionModal';

const TimeApprovalsPageWrapper = ({ children }: { children: React.ReactNode }) => {
  const { _ } = useLingui();
  const dataRetentionProvider = useRetentionModalProvider();

  return (
    <DashboardContainer showSecondaryNav>
      <Helmet>
        <title>{_(msg`Time Sources | Sage Intelligent Time`)}</title>
      </Helmet>

      <ErrorBoundary fallbackUI={(error) => <ErrorFallback error={error} />}>
        <Suspense fallback={null}>
          <RetentionModalContext.Provider value={dataRetentionProvider}>
            <PageContent>
              {children}
              {dataRetentionProvider.options && <RetentionModal />}
            </PageContent>
          </RetentionModalContext.Provider>
        </Suspense>
      </ErrorBoundary>
    </DashboardContainer>
  );
};

export default TimeApprovalsPageWrapper;
