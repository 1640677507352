import { loadingChunkRetry } from '@sit/client-shared';
import ErrorBoundary from '@web/components/ErrorBoundary';
import { NotFoundMessage } from '@web/containers/NotFoundPage/NotFoundMessage';
import TimeSources from '@web/containers/TimeSources';
import TimeApprovalsPageWrapper from '@web/containers/TimeSources/Layout';
import { lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

const TimeAssistant = lazy(() => loadingChunkRetry(() => import('@web/containers/TimeSources/TimeAssistant')));
const MissingPermissions = lazy(() => loadingChunkRetry(() => import('@web/containers/TimeSources/MissingPermission')));
const ConnectTimeSource = lazy(() => loadingChunkRetry(() => import('@web/containers/TimeSources/ConnectTimeSource')));
const ConnectTimeSourceSuccess = lazy(() => loadingChunkRetry(() => import('@web/containers/TimeSources/ConnectTimeSourceSuccess')));

const TimeSourcesIndex = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <TimeApprovalsPageWrapper>
            <TimeSources />
          </TimeApprovalsPageWrapper>
        }
      />
      <Route
        path="time-assistant"
        element={
          <TimeApprovalsPageWrapper>
            <TimeAssistant />
          </TimeApprovalsPageWrapper>
        }
      />
      <Route
        path="calendar-missing-permission"
        element={
          <TimeApprovalsPageWrapper>
            <MissingPermissions source="calendar" />
          </TimeApprovalsPageWrapper>
        }
      />
      <Route
        path=":sourceType/*"
        element={
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        }
      >
        <Route
          index
          element={
            <TimeApprovalsPageWrapper>
              <ConnectTimeSource />
            </TimeApprovalsPageWrapper>
          }
        />
        <Route
          path="success"
          element={
            <TimeApprovalsPageWrapper>
              <ConnectTimeSourceSuccess />
            </TimeApprovalsPageWrapper>
          }
        />
        <Route path="*" element={<NotFoundMessage />} />
      </Route>

      <Route path="*" element={<NotFoundMessage />} />
    </Routes>
  );
};

export default TimeSourcesIndex;
