import { NotificationGraphQl as PanelNotification } from '@sit/client-shared';
import { useNavigate } from 'react-router-dom';
import DefaultNotification from './notification/DefaultNotification';
import DescriptionNotification from './notification/DescriptionNotification';

interface NotificationProps {
  notification: PanelNotification;
}

const Notification = ({ notification }: NotificationProps): JSX.Element | null => {
  const navigate = useNavigate();
  const { meta, metaId } = notification;
  const isTimesheetNotification = ['TIMESHEET', 'SUBMIT_TIMESHEET', 'SAVE_TIMESHEET'].includes(meta || '');
  const handleClick = () => {
    if (isTimesheetNotification && metaId) {
      return navigate(`/timesheet/${metaId}`);
    }

    if (meta === 'TIMESHEETS') {
      return navigate('/timesheets');
    }

    if (meta === 'DATA_SOURCES') {
      return navigate('/data-sources');
    }
  };

  if (isTimesheetNotification) {
    return <DescriptionNotification notification={notification} onClick={handleClick} />;
  }

  return <DefaultNotification notification={notification} onClick={handleClick} />;
};

export default Notification;
