import {
  LoginResponse,
  PostApiAuthLoginError,
  PostApiAuthLoginVariables,
  setCompanyForSessionStorage,
  usePostLogin as usePostApiAuthUserLogin,
} from '@sit/client-shared';
import { UseMutationOptions } from '@tanstack/react-query';
import { track } from '@web/analytics/analyticsClient';
import trackEvents from '@web/analytics/trackEvents';
import { setAuthTokens } from '@web/redux/authentication-slice';
import { useAppDispatch } from '@web/redux/store';

export function usePostLogin(
  options?: Omit<UseMutationOptions<LoginResponse, PostApiAuthLoginError, PostApiAuthLoginVariables, unknown>, 'mutationFn'> | undefined,
) {
  const dispatch = useAppDispatch();
  return usePostApiAuthUserLogin({
    ...options,
    onSuccess: (data) => {
      dispatch(setAuthTokens(data));
      track(trackEvents.LOGIN_SUCCESS);
      setCompanyForSessionStorage(data.user.defaultCompanyId);
    },
    onError: (error) => {
      if (!error?.message?.includes('Two-Factor authentication is required')) {
        track(trackEvents.LOGIN_ERROR);
      }
    },
  });
}
