import Preview from 'carbon-react/lib/components/preview';
import styled from 'styled-components';

const Root = styled.div`
  margin: auto;
  padding-bottom: 400px;
  padding-top: 40px;
  max-width: 900px;
  min-width: 280px;
  width: 70%;
`;

const PreviewComponent = () => {
  return (
    <Root>
      <Preview lines={24} loading />
    </Root>
  );
};

export default PreviewComponent;
