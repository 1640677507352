import { GetApiUserSettingsError, GetApiUserSettingsResponse, useGetUserSettings } from '@sit/client-shared';
import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

export function useShowIntacctId(
  options?:
    | Omit<
        UseQueryOptions<GetApiUserSettingsResponse, GetApiUserSettingsError, GetApiUserSettingsResponse, QueryKey>,
        'queryKey' | 'queryFn' | 'initialData'
      >
    | undefined,
) {
  const { data } = useGetUserSettings(options);
  return data?.showIntacctId ?? false;
}
