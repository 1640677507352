import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Message from 'carbon-react/lib/components/message';
import Typography from 'carbon-react/lib/components/typography';
import { useFormik } from 'formik';
import styled from 'styled-components';
import PublicButton from '../../components/Shared/Public/PublicButton';
import PublicForm from '../../components/Shared/Public/PublicForm';
import PublicTextBox from '../../components/Shared/Public/PublicTextBox';
import { MfaTypes } from './MfaTypes';

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 32px;
`;

const AlternativeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  gap: 8px;
`;

const ErrorWrapper = styled.div`
  margin-top: 20px;
`;

const Or = styled(Typography)`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 100%;
`;

export interface MfaFormValues {
  code?: string;
}

interface MfaFormProps {
  onSubmit: (values: MfaFormValues) => void;
  loading: boolean | undefined;
  resend: () => void;
  type: MfaTypes;
  initialValues?: MfaFormValues;
  disableTypeSwitch?: boolean;
  requestSwitch?: (type: MfaTypes) => void;
  errorMsg?: string;
}

const INITIAL_VALUES: MfaFormValues = {
  code: '',
};

const useValidate = () => {
  const { _ } = useLingui();
  return (values: MfaFormValues) => {
    const errors: Partial<MfaFormValues> = {};

    if (!values.code) {
      errors.code = _(msg`Required`);
    } else if (values.code.length < 7) {
      errors.code = _(msg`Must be at least ${7} characters`);
    }

    return errors;
  };
};

const MfaForm = ({
  initialValues = INITIAL_VALUES,
  onSubmit,
  loading,
  resend,
  type,
  disableTypeSwitch = false,
  requestSwitch,
  errorMsg,
}: MfaFormProps) => {
  const { _ } = useLingui();
  const validate = useValidate();
  const { handleSubmit, values, errors, handleChange, setFieldError } = useFormik<MfaFormValues>({
    initialValues,
    validate,
    isInitialValid: false,
    validateOnChange: false,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const getOtherType = () => {
    if (type === MfaTypes.Email) {
      return MfaTypes.Phone;
    }

    return MfaTypes.Email;
  };

  const mask = (changeHandler: typeof handleChange) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const isOnlyNumbers = /^\d+$/.test(value);

    if (value === '' || isOnlyNumbers) return changeHandler(e);

    setFieldError('code', 'Must be only numbers');

    return;
  };

  const { code } = values;

  const footer = !disableTypeSwitch ? (
    <AlternativeContainer>
      <Or>
        <Trans>Or</Trans>
      </Or>
      <PublicButton buttonType="tertiary" onClick={() => requestSwitch?.(getOtherType())} label={_(msg`Receive code via email`)} />
    </AlternativeContainer>
  ) : null;

  return (
    <PublicForm
      title={_(msg`2-step authentication`)}
      subTitle={
        <>
          {type === MfaTypes.Email ? (
            <Trans>
              We sent a code to your <strong>email</strong>
            </Trans>
          ) : (
            <Trans>
              We sent a code to your <strong>phone number</strong>
            </Trans>
          )}
        </>
      }
      footerComponent={footer}
    >
      <form onSubmit={handleSubmit}>
        <PublicTextBox
          autoComplete="one-time-code"
          autoFocus
          label={_(msg`Security code`)}
          name="code"
          onChange={mask(handleChange)}
          // @ts-expect-error typing broken for carbon-react, remove this once they fix it
          type="text"
          value={code}
          error={errors.code}
          maxLength={7}
          pattern="\d*"
        />
        {errorMsg && !errorMsg.startsWith('Two-Factor authentication is required') && (
          <ErrorWrapper data-testid="Login-error-msg">
            <Message variant="error">{errorMsg}</Message>
          </ErrorWrapper>
        )}
        <Actions>
          <PublicButton buttonType="tertiary" onClick={() => resend()} label={_(msg`Resend`)} />
          <PublicButton buttonType="primary" type="submit" loading={loading} label={_(msg`Submit`)} />
        </Actions>
      </form>
    </PublicForm>
  );
};

export default MfaForm;
