import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { loadingChunkRetry } from '@sit/client-shared';
import ErrorBoundary from '@web/components/ErrorBoundary';

import DashboardContainer from '@web/containers/DashboardContainer';
import { ErrorComponent } from '@web/containers/TimeApprovals/Error';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';

const TimeApprovalPage = lazy(() => loadingChunkRetry(() => import('@web/containers/TimeApproval')));

const TimesheetApproval = () => {
  const { _ } = useLingui();

  return (
    <DashboardContainer>
      <Helmet>
        <title>{_(msg`Approve Timesheets | Sage Intelligent Time`)}</title>
      </Helmet>

      <ErrorBoundary fallbackUI={(error) => <ErrorComponent error={error} />}>
        <Suspense fallback={null}>
          <TimeApprovalPage />
        </Suspense>
      </ErrorBoundary>
    </DashboardContainer>
  );
};

export default TimesheetApproval;
