import { ErrorMessage } from '@web/components/ErrorBoundary/ErrorMessage';
import { isNotFoundError } from '@web/hooks/useNotFound';
import { NotFoundMessage } from '../NotFoundPage/NotFoundMessage';

interface ErrorProps {
  error: Error;
}

export const ErrorFallback = ({ error }: ErrorProps) => {
  if (isNotFoundError(error)) {
    return <NotFoundMessage />;
  }

  return <ErrorMessage error={error} />;
};
