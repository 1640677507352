import { PushNotification } from '@sit/client-shared';
import isEqual from 'lodash/isEqual';
import { useCallback, useContext, useEffect, useState } from 'react';
import { NotificationHandler, NotificationTopic } from '../NotificationHandlerService';
import { NotificationHandlerContext } from '../NotificationProvider';

const handleNotificationMatch = (
  notification: PushNotification,
  topic: NotificationTopic,
  params: Record<string | number, unknown> | null = null,
): PushNotification | null => {
  const topicMatch = notification.topic === topic;

  const { data } = notification;
  const paramsMatch = !params ? true : data && Object.keys(params).every((key) => isEqual(data[key], params[key]));

  return topicMatch && paramsMatch ? notification : null;
};

export const useOnLatestNotification = (topic: NotificationTopic, handler: NotificationHandler) => {
  const { service: notificationHandlerService } = useContext(NotificationHandlerContext);

  return useEffect(() => notificationHandlerService.addHandler(topic, handler), [topic, handler, notificationHandlerService]);
};

export function useLatestNotification(
  topic: NotificationTopic,
  params?: Record<string | number, unknown>,
): {
  notification: PushNotification | undefined;
  subscribe: (handler: NotificationHandler) => () => void;
} {
  const { service: notificationHandlerService } = useContext(NotificationHandlerContext);
  const [notification, setNotification] = useState<PushNotification | undefined>(undefined);

  const handleNotification = useCallback(
    (notification: PushNotification): void => {
      handleNotificationMatch(notification, topic, params) && setNotification(notification);
    },
    [params, topic],
  );

  const subscribe = useCallback<(handler: NotificationHandler) => () => void>(
    (handler) => notificationHandlerService.addHandler(topic, handler),
    [notificationHandlerService, topic],
  );

  useEffect(
    () => notificationHandlerService.addHandler(topic, handleNotification),
    [topic, handleNotification, notificationHandlerService],
  );

  return { notification, subscribe };
}
