import Button from '@web/components/Shared/Button';
import styled, { css } from 'styled-components';

const ConnectButton = styled(Button)`
  width: fit-content;
  min-width: 81px;
`;

const cardDefaultStyle = css`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.palette.slateTint(80)};
  border-radius: ${({ theme }) => theme.spacing / 2}px;
  display: flex;
  flex-direction: column;
  height: 222px;
  justify-content: space-between;
  padding: 40px 25px;
  width: 254px;
`;
const cardEmbeddedStyle = css`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.palette.slateTint(80)};
  display: flex;
  flex-direction: column;
  height: 194px;
  gap: ${({ theme }) => theme.space[2]};
  padding: ${({ theme }) => theme.space[3]};
  width: 254px;
  border-radius: ${({ theme }) => theme.spacing / 2}px;
`;

const Card = styled.div`
  ${({ theme }) => (theme.name === 'SIT-Embeded' ? cardEmbeddedStyle : cardDefaultStyle)}
`;

const connectedSourcesTitleDefaultStyle = css`
  color: ${({ theme }) => theme.palette.blackOpacity(0.9)};
  font-size: 24px;
  font-weight: 700;
`;
const connectedSourcesTitleEmbeddedStyle = css`
  color: ${({ theme }) => theme.palette.blackOpacity(0.9)};
  font-size: 20px;
  font-weight: 500;
`;

const Title = styled.div`
  ${({ theme }) => (theme.name === 'SIT-Embeded' ? connectedSourcesTitleEmbeddedStyle : connectedSourcesTitleDefaultStyle)}
`;
const Description = styled.div`
  color: ${({ theme }) => theme.palette.blackOpacity(0.9)};
  font-size: 14px;
  font-weight: 400;
`;

interface SourceCardProps {
  title: React.ReactNode;
  description: React.ReactNode;
  onClick?: () => void;
  buttonLabel: React.ReactNode;
  'data-pendo-id'?: string;
  disabled?: boolean;
}
export const SourceCard = ({ title, description, buttonLabel, onClick, ...props }: SourceCardProps) => {
  return (
    <Card>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <ConnectButton {...props} onClick={onClick}>
        {buttonLabel}
      </ConnectButton>
    </Card>
  );
};
