import moment from 'moment';

/* 15 => 3PM */
const intToTime = (int: number) => {
  if (int === 0) return '12AM';
  if (int < 12) return `${int}AM`;
  if (int === 12) return `${int}PM`;
  if (int === 24) return '11:59PM';
  return `${int - 12}PM`;
};

const halfHours = (() => {
  const res: string[] = [];
  const time = moment('00:30', 'hh:mm');
  while (time.format('HH:mm') !== '00:00') {
    res.push(time.format('HH:mm'));
    time.add(30, 'minutes');
  }
  res.push('24:00');
  return res;
})() as readonly string[];

const secondsToHours = (seconds = 0) => {
  if (typeof seconds === 'number') return moment.duration(seconds, 'seconds').asHours();
  return moment.duration(Number.parseInt(seconds || 0, 10), 'seconds').asHours();
};

const secondsToHoursFixed = (seconds = 0) => {
  return secondsToHours(seconds).toFixed(2);
};

const getTimesheetDays = (timesheetStart: moment.MomentInput, timesheetEnd: moment.MomentInput) => {
  const start = moment(timesheetStart);
  const end = moment(timesheetEnd);
  const daysDiff = end.diff(start, 'days');
  const days = [start];
  for (let i = 1; i <= daysDiff; i++) {
    days.push(start.clone().add(i, 'days'));
  }
  return days;
};

export { halfHours, getTimesheetDays, intToTime, secondsToHours, secondsToHoursFixed };
