import { loadingChunkRetry } from '@sit/client-shared';
import { useTimesheetDescription } from '@web/redux/hooks';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import DashboardContainer from '../DashboardContainer';
import { ErrorComponent } from './Error';
import PreviewComponent from './PreviewComponent';

const Timesheet = lazy(() => loadingChunkRetry(() => import('./Timesheet')));

const TimesheetContainer = () => {
  const { id: timesheetId } = useParams<{ id: string }>() as { id: string };
  const description = useTimesheetDescription(timesheetId);

  return (
    <DashboardContainer showSecondaryNav={false}>
      <Helmet>
        <title>{`${description || 'Timesheet'} | Sage Intelligent Time`}</title>
      </Helmet>
      <ErrorBoundary fallbackUI={(error, reset) => <ErrorComponent error={error} resetError={reset} />}>
        <Suspense fallback={<PreviewComponent />}>
          <Timesheet timesheetId={timesheetId} />
        </Suspense>
      </ErrorBoundary>
    </DashboardContainer>
  );
};

export default TimesheetContainer;
