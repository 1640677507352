import { timePreferencesRoute } from '@web/containers/TimePreferences/helpers/routes';
import { useMatch, useNavigate } from 'react-router-dom';

export const useTimePreferenceTabs = () => {
  const navigate = useNavigate();

  const handleTabChange = (tab: string) => {
    navigate(tab);
  };

  const match = useMatch(`${timePreferencesRoute}/:tabSlug?/*`);
  const selectedTabId = match?.params.tabSlug ?? 'time-gathering';

  return {
    handleTabChange,
    selectedTabId,
  };
};
