import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { logout } from './authentication-slice';

const initialState = {
  dimensionsExpanded: false,
};

const localSlice = createSlice({
  name: 'local',
  initialState,
  reducers: {
    setDimensionsExpanded: (state, action: PayloadAction<boolean>) => {
      state.dimensionsExpanded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.type, () => initialState);
  },
});

export const { setDimensionsExpanded } = localSlice.actions;
export default localSlice.reducer;
