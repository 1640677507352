import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useGetUser, usePostUserVerifyEmail } from '@sit/client-shared';
import toastr from '@web/helpers/toastr';
import { setAuthTokens } from '@web/redux/authentication-slice';
import { useIsAuthenticated } from '@web/redux/hooks';
import { useAppDispatch } from '@web/redux/store';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

function useVerifyEmail() {
  const { _ } = useLingui();
  const dispatch = useAppDispatch();
  return usePostUserVerifyEmail({
    onSuccess: (data) => {
      dispatch(setAuthTokens(data));
      toastr.success(_(msg`Email verified`));
    },
  });
}

const VerifyEmail = () => {
  const isAuthenticated = useIsAuthenticated();
  const { isLoading: isUserLoading, error: userError } = useGetUser({
    enabled: isAuthenticated,
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const confirmationToken = searchParams.get('confirmationToken');
  const { mutate: verifyEmail, isLoading, error } = useVerifyEmail();

  useEffect(() => {
    if (!confirmationToken) return;
    verifyEmail({
      queryParams: {
        confirmationToken,
      },
      requestConfig: {
        skipToast: true,
      },
    });
  }, [confirmationToken, verifyEmail]);

  if (!confirmationToken) {
    return (
      <div className="register" style={{ flexDirection: 'column' }}>
        <Trans>Token missing</Trans>
      </div>
    );
  }

  const errorMessage = error?.message || userError?.message;

  if (errorMessage)
    return (
      <div className="register" style={{ flexDirection: 'column' }}>
        <p>{errorMessage}</p>
      </div>
    );

  if (isLoading || isUserLoading) return null;

  navigate('/timesheets', { replace: true });

  return null;
};

export default VerifyEmail;
