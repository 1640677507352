import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useGetUser } from '@sit/client-shared';
import { usePostTimesheetDuplicate } from '@web/api/timesheet/use-post-timesheet-duplicate';
import Dialog from '@web/components/Shared/Dialog';
import { toUtcDate } from '@web/helpers/date';
import Loader from 'carbon-react/lib/components/loader';
import moment from 'moment';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import toastr from '../../../helpers/toastr';
import { useTimesheet } from '../../../hooks/useTimesheet';
import { getTimesheetsRoute } from '../helpers/routes';
import { useTimesheetModals } from '../hooks';
import { CreateTimesheetModal, type FormData } from './CreateTimesheetModal';
import { mustHaveEmployee, noEditPermissions } from './messages';

const TIME_FORMAT = 'YYYY-MM-DD';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
`;

const LoadingModal = () => {
  return (
    <Dialog disableEscKey={false} enableBackgroundUI={false} open disableAutoFocus data-cy="create-timesheet-modal">
      <LoadingContainer>
        <Loader size="large" />
      </LoadingContainer>
    </Dialog>
  );
};

const useDuplicateData = (timesheetId: string) => {
  const { timesheet, error, loading } = useTimesheet(timesheetId);

  if (timesheet) {
    const { startDate: timesheetStart, endDate: timesheetEnd, description: timesheetDescription } = timesheet;

    const startDate = moment(toUtcDate(timesheetStart ? new Date(timesheetStart) : new Date()));
    const endDate = moment(toUtcDate(timesheetEnd ? new Date(timesheetEnd) : new Date()));

    const description = timesheetDescription ? `${timesheetDescription} Copy` : 'Copy';

    const data = {
      startDate,
      endDate,
      description,
    };

    return {
      data,
      error: null,
      loading,
    };
  }

  return { data: null, error, loading };
};

interface DuplicateTimesheetProps {
  staff: boolean;
  timesheetId: string;
}

export const DuplicateTimesheet = ({ staff, timesheetId }: DuplicateTimesheetProps) => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const { closeModal } = useTimesheetModals();
  const { data } = useDuplicateData(timesheetId);
  const { mutate: duplicateTimesheet } = usePostTimesheetDuplicate();
  const { data: userData } = useGetUser();
  const { canEditTimesheets, canCreateTimesheet } = userData || {};

  const handleSubmit = ({ startDate, endDate, description, includeEntries }: FormData) => {
    duplicateTimesheet(
      {
        body: {
          description,
          endDate: endDate.format(TIME_FORMAT),
          startDate: startDate.format(TIME_FORMAT),
        },
        pathParams: {
          id: timesheetId,
        },
        queryParams: {
          includeEntries: includeEntries ? 'true' : 'false',
        },
      },
      {
        onSuccess: (data) => {
          navigate(`/timesheet/${data.id}${staff ? '?staff=true' : ''}`);
        },
      },
    );
  };

  if (!canEditTimesheets || !canCreateTimesheet) {
    const messageDescriptor = !canEditTimesheets ? noEditPermissions : mustHaveEmployee;
    toastr.warning(_(messageDescriptor));
    return <Navigate to={getTimesheetsRoute} />;
  }

  if (!data) {
    return <LoadingModal />;
  }

  return (
    <CreateTimesheetModal
      title={_(msg`Duplicate timesheet`)}
      showIncludeEntriesBox={true}
      data={data}
      onClose={closeModal}
      onSubmit={handleSubmit}
      submitButtonText={_(msg`Duplicate timesheet`)}
    />
  );
};
