import { t } from '@lingui/macro';
import { ErrorClassification } from '@sit/client-shared';
import toastr from './toastr';

export function updateNetworkStatus(isOnline: boolean) {
  if (isOnline) {
    toastr.info(t`Internet connection restored`, {
      toastId: 'API_NETWORK_INFO_ONLINE',
      dismissToastId: ErrorClassification.NETWORK_OFFLINE,
    });
  } else {
    toastr.error(t`No internet connection`, {
      toastId: ErrorClassification.NETWORK_OFFLINE,
      dismissToastId: ['API_NETWORK_INFO_ONLINE', ErrorClassification.NETWORK_SERVER_DOWN],
    });
  }
}

window.addEventListener('online', () => updateNetworkStatus(true));
window.addEventListener('offline', () => updateNetworkStatus(false));
