import DashboardContainer from '../DashboardContainer';
import { NotFoundMessage } from './NotFoundMessage';

const NotFoundPage = () => {
  return (
    <DashboardContainer showSecondaryNav>
      <NotFoundMessage />
    </DashboardContainer>
  );
};

export default NotFoundPage;
