import {
  PostApiTimesheetsByUuidSubmitAsyncError,
  PostApiTimesheetsByUuidSubmitAsyncResponse,
  PostApiTimesheetsByUuidSubmitAsyncVariables,
  usePostTimesheetSubmitAsync as usePostApiTimesheetsByUuidSubmitAsync,
} from '@sit/client-shared';
import { UseMutationOptions } from '@tanstack/react-query';
import toastr from '@web/helpers/toastr';

export const usePostTimesheetSubmitAsync = (
  options?:
    | Omit<
        UseMutationOptions<
          PostApiTimesheetsByUuidSubmitAsyncResponse,
          PostApiTimesheetsByUuidSubmitAsyncError,
          PostApiTimesheetsByUuidSubmitAsyncVariables,
          unknown
        >,
        'mutationFn'
      >
    | undefined,
) => {
  return usePostApiTimesheetsByUuidSubmitAsync({
    onSuccess: async (data, variables, context) => {
      const timesheetId = variables.pathParams.id;
      await options?.onSuccess?.(data, variables, context);
      toastr.info(`Submitting your timesheet, we'll notify you when we finish.`, { toastId: timesheetId });
    },
  });
};
