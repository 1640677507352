import styled, { css } from 'styled-components';

const DefaultExperienceStyle = css<{ fullscreen?: boolean }>`
  max-width: ${({ fullscreen }) => (fullscreen ? 'none' : '1800px')};
  padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[5]} 0 ${({ theme }) => theme.space[5]};
`;

const EmbeddedExperienceStyle = css`
  max-width: none;
  padding: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[3]};
`;

const Root = styled.div`
  margin: auto;

  ${({ theme }) => (theme.name === 'SIT-Embeded' ? EmbeddedExperienceStyle : DefaultExperienceStyle)}
`;

interface PageContentProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  fullscreen?: boolean;
}

export const PageContent = ({ children, ...props }: PageContentProps) => {
  return <Root {...props}>{children}</Root>;
};
