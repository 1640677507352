import { useGetTimesheet } from '@sit/client-shared';
import { NotificationTopic } from '@web/components/Notifications/NotificationHandlerService';
import { useLatestNotification } from '@web/components/Notifications/hooks/useLatestNotification';
import { useIsStaff } from '@web/containers/Timesheets/hooks';
import { sendPrintToIntacct } from '@web/services/intacct';
import { getIntacctMod } from '@web/services/intacct-mod';
import { useEffect, useState } from 'react';
import { usePostTimesheetSaveAsync } from './use-post-timesheet-save-async';

export function useSendPrintToIntacct() {
  const isStaff = useIsStaff();
  const mod = getIntacctMod();
  const [timesheetId, setTimesheetId] = useState<string | null>(null);
  const [syncingToIntacct, setSyncingToIntacct] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { data: timesheetData } = useGetTimesheet(timesheetId ?? undefined);
  const { mutate: saveAsync } = usePostTimesheetSaveAsync();
  const { notification: syncResponse } = useLatestNotification(NotificationTopic.SAVE_TIMESHEET);

  const recordNo = timesheetData?.recordNo;

  useEffect(() => {
    if (isRefreshing && recordNo) {
      setIsRefreshing(false);
    }
  }, [recordNo, isRefreshing]);

  useEffect(() => {
    if (timesheetId == null || syncingToIntacct || isRefreshing) return;
    if (!recordNo) {
      saveAsync({ pathParams: { id: timesheetId } });
      setSyncingToIntacct(true);
    } else {
      sendPrintToIntacct(recordNo, mod, isStaff);
      setTimesheetId(null);
    }
  }, [saveAsync, timesheetId, isStaff, recordNo, mod, syncingToIntacct, isRefreshing]);

  useEffect(() => {
    if (timesheetId == null || !syncingToIntacct || !syncResponse) return;
    const { data } = syncResponse;
    // We have to do this instead of the `useLatestNotification` params hook.
    if (!('timesheetId' in data && data.timesheetId === timesheetId)) return;
    setSyncingToIntacct(false);
    setIsRefreshing(true);
  }, [syncResponse, syncingToIntacct, timesheetId]);

  return ({ timesheetId }: { timesheetId: string }) => {
    setTimesheetId(timesheetId);
  };
}
