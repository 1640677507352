import { useAppSelector } from '@web/redux/store';
import styled from 'styled-components';
import { config } from '../../config/env';
import { BuildType } from '../../redux/Development/types';
import { selectBuildType } from '../../redux/selectors';

const Root = styled.span`
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.blackOpacity(0.9)};
  font-size: 14px;
  font-weight: 600;
  height: 25px;
  border-left: 2px solid ${({ theme }) => theme.palette.blackOpacity(0.9)};
  margin: ${({ theme }) => theme.space[1]};
  padding: 0 ${({ theme }) => theme.space[2]};
`;

const useVersionString = () => {
  const buildType = useAppSelector(selectBuildType);
  const versionString = `Package version ${import.meta.env.VITE_APP_VERSION}`;

  if (buildType !== BuildType.default && !config.isProduction) {
    return `${versionString} (${buildType.toUpperCase()})`;
  }

  return versionString;
};

export const DebugVersion = () => {
  const versionString = useVersionString();

  if (config.isProduction) {
    return null;
  }

  return <Root>{versionString}</Root>;
};
