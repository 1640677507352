import { createContext, useContext, useState } from 'react';

export type RetentionTypes = 'email' | 'calendar' | 'activities';

export interface RetentionModalOptions {
  type: RetentionTypes;
  title: string;
  fieldLabel: string;
  isEditing?: boolean;
  onAction?: (retention: number) => void;
}

interface OpenModalOptions extends RetentionModalOptions {
  retention?: number | null;
}

interface RetentionModalContext {
  options: RetentionModalOptions | null;
  retention: number | null;
  setRetention: (retention: number) => void;
  openModal: (options: OpenModalOptions) => void;
  closeModal: () => void;
}

const RetentionModalContext = createContext<RetentionModalContext | null>(null);

function useRetentionModal(options: RetentionModalOptions) {
  const context = useContext(RetentionModalContext);

  if (!context) {
    return {
      context: null,
      openModal: () => undefined,
      closeModal: () => undefined,
    };
  }

  const { openModal, closeModal } = context;

  return {
    context,
    openModal: (overrideOptions: Partial<OpenModalOptions>) => openModal({ ...options, ...overrideOptions }),
    closeModal,
  };
}

export default useRetentionModal;

function useRetentionModalProvider() {
  const [options, setOptions] = useState<RetentionModalOptions | null>(null);
  const [retention, setRetention] = useState<number | null>(null);

  function openModal(options: OpenModalOptions) {
    const { retention = null, ...overrideOptions } = options;

    setRetention(retention);
    setOptions(overrideOptions);
  }

  function closeModal() {
    setRetention(null);
    setOptions(null);
  }

  return {
    options,
    retention,
    setRetention,
    openModal,
    closeModal,
  };
}

export { RetentionModalContext, useRetentionModalProvider };
