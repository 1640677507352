import Password, { PasswordProps } from 'carbon-react/lib/components/password';
import styled from 'styled-components';

export interface PublicPasswordProps extends PasswordProps {
  containerProps?: any;
}

const TextBoxContainer = styled.div`
  label {
    font-weight: 400;
    color: ${(props) => props.theme.palette.black};
  }
`;

const PublicPassword = ({ containerProps, children, ...props }: PublicPasswordProps) => {
  return (
    <TextBoxContainer {...containerProps}>
      <Password {...props}>{children}</Password>
    </TextBoxContainer>
  );
};

export default PublicPassword;
