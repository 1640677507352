import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

interface TitleProps {
  withSubtitle: boolean;
}

const Title = styled.div<TitleProps>`
  color: ${(props) => props.theme.palette.blackOpacity(0.9)};
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: ${({ withSubtitle }) => (withSubtitle ? '10px' : '50px')};
  text-align: center;
  width: 100%;
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.palette.blackOpacity(0.9)};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
`;

const Form = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  max-width: 448px;
  min-width: 324px;
  padding: 24px 32px;
  width: 100%;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormContent = styled.div`
  width: 100%;

  div[data-element='form-summary'] {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const FootContainer = styled.div`
  width: 100%;
`;

const Divider = styled.hr`
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.blackOpacity(0.3)};
  margin: 32px 0 16px;
  border-bottom: 0;
`;

export interface PublicFormProps {
  footerComponent?: ReactNode;
  title?: string;
  subTitle?: React.ReactNode;
}

const PublicForm = ({ children, footerComponent, title, subTitle }: PropsWithChildren<PublicFormProps>) => {
  return (
    <Form>
      {title && (
        <Title data-testid="PublicFormTitle" withSubtitle={!!subTitle}>
          {title}
        </Title>
      )}
      {subTitle && <SubTitle data-testid="PublicFormSubtitle">{subTitle}</SubTitle>}
      <FormContent>{children}</FormContent>
      {footerComponent && (
        <>
          <Divider />
          <FootContainer>{footerComponent}</FootContainer>
        </>
      )}
    </Form>
  );
};

export default PublicForm;
