import {
  PostApiTimesheetsDuplicateError,
  PostApiTimesheetsDuplicateVariables,
  TimesheetType,
  usePostTimesheetDuplicate as useDuplicate,
} from '@sit/client-shared';
import { UseMutationOptions } from '@tanstack/react-query';
import { track } from '@web/analytics/analyticsClient';
import trackEvents from '@web/analytics/trackEvents';

export function usePostTimesheetDuplicate(
  options?:
    | Omit<UseMutationOptions<TimesheetType, PostApiTimesheetsDuplicateError, PostApiTimesheetsDuplicateVariables, unknown>, 'mutationFn'>
    | undefined,
) {
  return useDuplicate({
    ...options,
    onSuccess: (data, variables, context) => {
      if (!data?.id) return;
      track(trackEvents.TIMESHEET_DUPLICATE);
      options?.onSuccess?.(data, variables, context);
    },
  });
}
