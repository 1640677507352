import { usePostTimesheetSaveSettled } from '@sit/client-shared';
import { track } from '@web/analytics/analyticsClient';
import trackEvents from '@web/analytics/trackEvents';
import { useAppDispatch } from '@web/redux/store';
import { setSyncStatus } from '@web/redux/timesheets-slice';
import { useCallback } from 'react';

export const useTimesheetSaveSettled = () => {
  const dispatch = useAppDispatch();
  const onSettle = usePostTimesheetSaveSettled();
  return useCallback(
    (timesheetId: string, isSuccess: boolean) => {
      onSettle(timesheetId);
      if (isSuccess) {
        dispatch(setSyncStatus({ timesheetId, synced: true }));
        track(trackEvents.TIMESHEET_SAVE);
      }
    },
    [dispatch, onSettle],
  );
};
