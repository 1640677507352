import StatusPill from '@web/components/StatusPill';
import { useGetPillInfo } from '@web/helpers/pills';
import { secondsToHoursFixed } from '@web/helpers/time';
import Preview from 'carbon-react/lib/components/preview';
import Typography from 'carbon-react/lib/components/typography';
import VerticalDivider from 'carbon-react/lib/components/vertical-divider';
import { format } from 'date-fns';
import React from 'react';
import styled, { css } from 'styled-components';

type StickPositions = 'top' | 'left' | 'right' | 'bottom';

const RootStickAt = css<{ stickAt?: StickPositions }>`
  position: sticky;
  ${({ stickAt = 'top' }) => `${stickAt}: 0`};
`;

const Root = styled.div<{ stickAt?: StickPositions }>`
  flex-basis: 195px;
  flex-shrink: 0;
  height: 96px;
  display: flex;

  ${({ stickAt }) => stickAt && RootStickAt}
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 ${({ theme }) => theme.space[2]};
  text-align: center;
  gap: ${({ theme }) => theme.space[1]};
  background-color: ${({ theme }) => theme.colors.white};
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;
const Value = styled.div`
  width: 100%;
  & > h4 {
    line-height: 26px;
    width: 100%;
    text-align: center;
  }
`;

const BigTextValue = styled(Value)`
  color: ${({ theme }) => theme.palette.black};
  font-size: 24px;
  font-weight: 700;
`;

interface StatusCardBaseCommonProps extends React.HTMLAttributes<HTMLDivElement> {
  label: React.ReactNode;
  stickAt?: StickPositions;
  withDivider?: boolean;
  'data-testid'?: string;
}

type StatusCardBasePropsCommonWithValue<Value extends React.ReactNode> = StatusCardBaseCommonProps & { value: Value };
type StatusCardBasePropsCommonWithValueComponent = StatusCardBaseCommonProps & { valueComponent: React.ReactNode };

type StatusCardBaseProps<Value extends React.ReactNode> =
  | StatusCardBasePropsCommonWithValue<Value>
  | StatusCardBasePropsCommonWithValueComponent;

const StatusCardBase = ({ label, stickAt, withDivider, ...props }: StatusCardBaseProps<string>) => {
  const haveValueComponent = 'valueComponent' in props;
  return (
    <Root stickAt={stickAt}>
      {withDivider && <VerticalDivider py={2} px={0} />}
      <Item {...props}>
        <Label>{label}</Label>
        <Value data-testid="stat-value">
          {haveValueComponent ? (
            props.valueComponent
          ) : (
            <Typography variant="h4" truncate mt={0} mb={0}>
              {props.value}
            </Typography>
          )}
        </Value>
      </Item>
    </Root>
  );
};

interface StatusCardPropsBase<Value, Variant extends string> extends StatusCardBaseCommonProps {
  type: Variant;
  value: Value;
}

export type StatusCardProps =
  | StatusCardPropsBase<Date, 'date'>
  | StatusCardPropsBase<number, 'duration'>
  | StatusCardPropsBase<string, 'pill-status'>
  | StatusCardPropsBase<void, 'preview'>;

const StatusCard = ({ type, value, ...props }: StatusCardProps) => {
  const pillInfo = useGetPillInfo(type === 'pill-status' ? value : undefined);
  switch (type) {
    case 'date':
      return <StatusCardBase {...props} value={format(value, 'MM/dd/yyyy')} />;
    case 'duration':
      return <StatusCardBase {...props} valueComponent={<BigTextValue>{secondsToHoursFixed(value)}</BigTextValue>} />;
    case 'pill-status': {
      return <StatusCardBase valueComponent={<StatusPill text={pillInfo.pillText} type={pillInfo.pillType} />} {...props} />;
    }
    case 'preview':
      return <StatusCardBase {...props} label={props.label || <Preview loading width="70px" />} valueComponent={<Preview loading />} />;
  }
};
export default StatusCard;
