import { default as CarbonTextbox, TextboxProps } from 'carbon-react/lib/components/textbox';
import { ReactNode } from 'react';
import styled from 'styled-components';

export interface PublicTextBoxProps extends Exclude<TextboxProps & React.RefAttributes<HTMLInputElement>, 'children'> {
  containerProps?: any;
  actionComponent?: ReactNode;
}

const TextBoxContainer = styled.div`
  div[role='presentation'] {
    border-radius: 4px;
  }

  label {
    font-weight: 400;
    color: ${(props) => props.theme.palette.black};
  }
`;

const PublicTextBox = ({ containerProps, actionComponent, ...props }: PublicTextBoxProps) => {
  return (
    <TextBoxContainer {...containerProps}>
      <CarbonTextbox {...props}>{actionComponent}</CarbonTextbox>
    </TextBoxContainer>
  );
};

export default PublicTextBox;
