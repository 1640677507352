import { validate as isUuid } from 'uuid';

export const handleParamsValidation = (paramsToValidate: Record<string, string>, params: Record<string, string | undefined>) => {
  return Object.keys(paramsToValidate).every((p) => {
    const type = paramsToValidate[p];
    const param = params[p];

    switch (type) {
      case 'uuid':
        return param ? isUuid(param) : false;

      default:
        return false;
    }
  });
};
