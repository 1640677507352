import { I18n } from '@lingui/core';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useGetApiAccounts } from '@sit/client-shared';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { accountTypes, timeSourcesDefaultQueryParams } from '../constants';
import { timeSourcesRoutes } from '../helpers/routes';

interface LocalizedSourceCardsOptions {
  title: string;
  isConfigured: boolean;
  configuredMessage?: string;
  defaultMessage?: string;
}

const makeLocalizedSourceCards =
  (_: I18n['_']) =>
  ({
    title,
    isConfigured,
    configuredMessage = _(msg`Connect another ${title.toLocaleLowerCase()} to auto-generate time entries.`),
    defaultMessage = _(msg`Pull in ${title.toLocaleLowerCase()}s events to auto-generate entries.`),
  }: LocalizedSourceCardsOptions) => {
    const description = isConfigured ? configuredMessage : defaultMessage;
    const buttonLabel = isConfigured ? _(msg`Connect another`) : _(msg`Connect`);

    return { title, description, buttonLabel, 'data-pendo-id': `connect-${title.toLocaleLowerCase()}` };
  };

function useSourceCards() {
  const { _ } = useLingui();
  const toLocalizedCardLabels = makeLocalizedSourceCards(_);
  const { data: accounts, isLoading: isLoadingAccounts } = useGetApiAccounts(
    {},
    {
      ...timeSourcesDefaultQueryParams,
      select: (data) => {
        return data.map((e) => e.type);
      },
    },
  );

  // const { data: timeAssistantConfigured, isLoading: isLoadingDataStatus } = useGetApiDataStatus(
  //   {},
  //   { ...timeSourcesDefaultQueryParams, select: ({ albert }) => albert !== 'albert_not_yet_synced' },
  // );
  // @TODO: Hardcoded until TA launch is fixed.
  const timeAssistantConfigured = false;

  const navigate = useNavigate();

  const sourceCards = useMemo(() => {
    return accountTypes.map((type) => {
      switch (type) {
        case 'CALENDAR':
          return {
            ...toLocalizedCardLabels({
              title: _(msg`Calendar`),
              isConfigured: accounts?.includes(type) ?? false,
              defaultMessage: _(msg`Pull in calendar events to auto-generate entries.`),
            }),
            onClick: () => navigate(timeSourcesRoutes.calendar),
          };
        case 'EMAIL':
          return {
            ...toLocalizedCardLabels({
              title: _(msg`Email`),
              isConfigured: accounts?.includes(type) ?? false,
              defaultMessage: _(msg`Pull in emails to auto-generate timesheet entries.`),
            }),
            onClick: () => navigate(timeSourcesRoutes.email),
          };
        case 'TIME_ASSISTANT':
          return {
            title: _(msg`Time Assistant`),
            description: timeAssistantConfigured
              ? _(msg`Your Time Assistant is setup, click below to launch it.`)
              : _(msg`Install the application to capture computer activity.`),
            buttonLabel: timeAssistantConfigured ? _(msg`Launch`) : _(msg`Download`),
            'data-pendo-id': timeAssistantConfigured ? 'launch-time-assistant' : 'download-time-assistant',
            onClick: () => (timeAssistantConfigured ? window.open('sittracker://param', '_self') : navigate('time-assistant')),
          };
      }
    });
  }, [accounts, navigate, _, toLocalizedCardLabels, timeAssistantConfigured]);

  return {
    sourceCards,
    isLoading: isLoadingAccounts, //|| isLoadingDataStatus, @TODO: Remove this when TA launch is fixed.
  };
}

export default useSourceCards;
