import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from './store';

type GeneralStore = {
  // Since we rely on persisting this data to local storage, we prefer to keep the existing
  // data structure and add new fields to it.
  // Ideally, this `data` would be removed. However, we need to keep it for backwards compatibility.
  data: {
    hideBrowserWarning: boolean;
    hideMaintenanceWarning: boolean;
    maintenanceWarningVersion: string;
  };
};

const initialState: GeneralStore = {
  data: {
    hideBrowserWarning: false,
    hideMaintenanceWarning: false,
    maintenanceWarningVersion: '',
  },
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    dismissBrowserWarning: (state) => {
      state.data.hideBrowserWarning = true;
    },
    dismissMaintenanceWarning: (state, action: PayloadAction<string>) => {
      state.data.hideMaintenanceWarning = true;
      state.data.maintenanceWarningVersion = action.payload;
    },
  },
});

export function useHideBrowserWarning() {
  return useAppSelector((state) => state.general?.data.hideBrowserWarning ?? false);
}
export function useHideMaintenanceWarning() {
  return useAppSelector((state) => state.general?.data.hideMaintenanceWarning ?? false);
}
export function useMaintenanceWarningVersion() {
  return useAppSelector((state) => state.general?.data.maintenanceWarningVersion || '');
}

export const { dismissBrowserWarning, dismissMaintenanceWarning } = generalSlice.actions;
export default generalSlice.reducer;
