import { useAppSelector } from '@web/redux/store';
import { selectBuildType } from '../../../redux/selectors';
import { defaultTheme, embedTheme } from '../themes';

export const useTheme = () => {
  const buildType = useAppSelector(selectBuildType);
  switch (buildType) {
    case 'embedded':
      return embedTheme;
    default:
      return defaultTheme;
  }
};
