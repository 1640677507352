import { transformGetApiTimesheetsResponse, useDeleteTimesheets } from '@sit/client-shared';
import { useGetTimesheets } from '@web/api/timesheets/use-get-timesheets';
import { useIsStaff, useTimesheetModals } from '../hooks';
import { DeleteTimesheetsModal } from './DeleteTimesheetsModal';

const useBulkDeleteModal = (timesheetIds: string[]) => {
  const { closeModal } = useTimesheetModals();
  const { mutate: bulkDelete } = useDeleteTimesheets();
  const staff = useIsStaff();
  const { data } = useGetTimesheets();

  const timesheets = transformGetApiTimesheetsResponse(data || []);

  const deleteTimesheets = () => {
    bulkDelete({
      queryParams: {
        staff: staff ? 'true' : 'false',
      },
      body: {
        id: timesheetIds,
      },
    });
  };

  return {
    ids: timesheetIds,
    timesheets,
    onClose: closeModal,
    onOk: deleteTimesheets,
  };
};

interface DuplicateTimesheetProps {
  timesheetIds: string[];
}

export const BulkDeleteTimesheets = ({ timesheetIds }: DuplicateTimesheetProps) => {
  const modalProps = useBulkDeleteModal(timesheetIds);

  return <DeleteTimesheetsModal {...modalProps} />;
};
