export const includeIntacctId = <D extends Record<PropertyKey, any>>(data: D, options?: { fieldName?: 'employeeId' }) => {
  const externalId = data[options?.fieldName ?? 'externalId'] as string | undefined;
  if (externalId) {
    const name = data.name ?? '';
    const textToPrepend = `${externalId}--`;

    return {
      ...data,
      name: name.includes(textToPrepend) ? name : `${textToPrepend}${name}`,
    };
  }
  return data;
};

export const sortObjectsByNameAsc = (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name);
export const sortObjectsByNameAscUnsafe = (a: { name: string | null | undefined }, b: { name: string | null | undefined }) => {
  if (a.name == null) return -1;
  if (b.name == null) return 1;
  return a.name.localeCompare(b.name);
};

export const capitalizeFirstLetter = (str: string | null | undefined) => {
  if (str == null) return '';
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};
