import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useGetTimesheet, useGetUser, type GetApiEmployeesResponse } from '@sit/client-shared';
import { useGetEmployees } from '@web/api/employees/use-get-employees';
import { FilterableSelect, Option, type CustomSelectChangeEvent } from 'carbon-react/lib/components/select';
import { useMemo } from 'react';
import styled from 'styled-components';

const Value = styled.div`
  color: ${({ theme }) => theme.palette.blackOpacity(0.9)};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  height: 40px;
  display: flex;
  align-items: center;
`;

const getEmployeeNameByEmployeeId = (employeeId: string, employeesData: GetApiEmployeesResponse['employees'] | undefined): string => {
  return employeesData?.find((e) => e.employeeId === employeeId)?.name ?? '';
};

const useEmployee = (
  timesheetId: string,
  employees: GetApiEmployeesResponse['employees'] | undefined,
  employeeId: string,
  setValue: (value: string) => void,
  readonly: boolean,
) => {
  // `readonly` is currently `true` under this condition:
  // !staff || timesheet.data.status !== 'DRAFT'
  // When `readonly` is `true`, we want to show the employee name in the select instead
  // of performing a network request to get the employees list.
  const { data: userData } = useGetUser();
  const { data: timesheet } = useGetTimesheet(timesheetId);

  const options = (() => {
    const optionsToRender = employees && employees.length > 0 ? employees : [{ employeeId: userData?.employeeId, name: userData?.name }];
    return optionsToRender.map((c) => ({ label: c.name, value: c.employeeId }));
  })();

  const handleChange = (e: CustomSelectChangeEvent) => setValue(e.target.value?.toString() || '');

  const label = useMemo(() => getEmployeeNameByEmployeeId(employeeId, employees), [employees, employeeId]);

  return {
    handleChange,
    options,
    label: readonly ? timesheet?.employeeName ?? '' : label,
  };
};

export interface EmployeeSelectorProps {
  timesheetId: string;
  readonly?: boolean;
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  employeeId: string;
  onChange: (value: string) => void;
}

export const EmployeeSelector = ({
  timesheetId,
  readonly = false,
  disabled,
  loading,
  employeeId,
  onChange,
  label,
}: EmployeeSelectorProps) => {
  const { _ } = useLingui();
  const { data } = useGetEmployees({ enabled: !readonly });
  const { handleChange, options, label: employeeLabel } = useEmployee(timesheetId, data?.employees, employeeId, onChange, readonly);

  if (readonly) {
    return <Value>{label ?? employeeLabel}</Value>;
  }

  return (
    <FilterableSelect
      onChange={handleChange}
      value={employeeId}
      placeholder={_(msg`Type to search`)}
      disabled={disabled}
      isLoading={loading}
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value ?? ''} text={option.label ?? ''} />
      ))}
    </FilterableSelect>
  );
};
