import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Helmet } from 'react-helmet';
import DashboardContainer from '../DashboardContainer';
import Timesheets from './Timesheets';
import { BulkDeleteTimesheets } from './components/BulkDeleteTimesheetsModal';
import { DuplicateTimesheet } from './components/DuplicateTimesheet';
import { NewTimesheet } from './components/NewTimesheet';
import { TimesheetModalsProvider } from './components/TimesheetModalsProvider';
import { TimesheetModalType } from './components/timesheet-modal-type';
import { useIsStaff, useTimesheetModals } from './hooks';

const TimesheetModals = () => {
  const { state } = useTimesheetModals();
  const staff = useIsStaff();

  switch (state.type) {
    case TimesheetModalType.NEW: {
      return <NewTimesheet />;
    }
    case TimesheetModalType.DUPLICATE: {
      return <DuplicateTimesheet staff={staff} timesheetId={state.timesheetId} />;
    }

    case TimesheetModalType.BULK_DELETE: {
      return <BulkDeleteTimesheets timesheetIds={state.timesheetIds} />;
    }

    default: {
      return null;
    }
  }
};

const TimesheetsContainer = () => {
  const { _ } = useLingui();
  return (
    <DashboardContainer showSecondaryNav>
      <Helmet>
        <title>{_(msg`Timesheets | Sage Intelligent Time`)}</title>
      </Helmet>
      <TimesheetModalsProvider>
        <>
          <TimesheetModals />
          <Timesheets />
        </>
      </TimesheetModalsProvider>
    </DashboardContainer>
  );
};

export default TimesheetsContainer;
