export const timeApprovalsRoute = '/time-approvals';

export const getTimeApprovalsTabsRoute = (tab: 'todo' | 'done' = 'todo') => {
  return `${timeApprovalsRoute}/${tab}` as const;
};

export const getTimeApprovalIdRoute = (timesheet: string) => {
  return `${timeApprovalsRoute}/${timesheet}` as const;
};

export const getTimeApprovalIdEntryModalRoute = (timesheet: string, entry: string) => {
  return `${timeApprovalsRoute}/${timesheet}/entry/${entry}` as const;
};
