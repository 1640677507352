import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PublicPassword from '@web/components/Shared/Public/PublicPassword';
import Form from 'carbon-react/lib/components/form';
import Message from 'carbon-react/lib/components/message';
import { useFormik } from 'formik';
import PublicButton from '../../components/Shared/Public/PublicButton';
import PublicForm from '../../components/Shared/Public/PublicForm';

export interface ResetPasswordFormValues {
  password: string;
  passwordConfirm: string;
}

export interface ResetPasswordFormProps {
  initialValues?: ResetPasswordFormValues;
  onSubmit: (values: ResetPasswordFormValues) => void;
  errorMsg?: string;
  passwordOnly?: boolean;
}

const INITIAL_VALUES: ResetPasswordFormValues = {
  password: '',
  passwordConfirm: '',
};

const useFormValidation = () => {
  const { _ } = useLingui();
  const validate = (values: ResetPasswordFormValues) => {
    const errors: Partial<ResetPasswordFormValues> = {};

    if (!values.password) {
      errors.password = _(msg`Required`);
    } else if (values.password.length < 8) {
      errors.password = _(msg`Must be at least 8 characters`);
    }

    if (!values.passwordConfirm) {
      errors.passwordConfirm = _(msg`Required`);
    } else if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = _(msg`Passwords don't match`);
    }

    return errors;
  };

  return validate;
};

const ResetPasswordForm = ({ errorMsg, onSubmit, initialValues = INITIAL_VALUES }: ResetPasswordFormProps) => {
  const { _ } = useLingui();
  const validate = useFormValidation();
  const { handleSubmit, values, errors, handleChange } = useFormik<ResetPasswordFormValues>({
    initialValues,
    validate,
    isInitialValid: false,
    validateOnChange: false,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const { password, passwordConfirm } = values;

  return (
    <PublicForm title={_(msg`Reset your password`)}>
      <Form saveButton={<PublicButton buttonType="primary" key="register" type="submit" label={_(msg`Save`)} />} onSubmit={handleSubmit}>
        <PublicPassword
          label={_(msg`New password`)}
          labelInline={false}
          labelAlign="left"
          name="password"
          onChange={handleChange}
          value={password}
          error={errors.password}
        />
        <PublicPassword
          label={_(msg`Confirm new password`)}
          labelInline={false}
          labelAlign="left"
          name="passwordConfirm"
          onChange={handleChange}
          value={passwordConfirm}
          error={errors.passwordConfirm}
        />
        {errorMsg && (
          <div className="error-wrapper">
            <Message variant="error">{errorMsg}</Message>
          </div>
        )}
      </Form>
    </PublicForm>
  );
};

export default ResetPasswordForm;
