export const timeSourcesRoute = '/data-sources';

export const timeSourcesRoutes = {
  email: `${timeSourcesRoute}/email`,
  calendar: `${timeSourcesRoute}/calendar`,
  timeAssistant: `${timeSourcesRoute}/time-assistant`,
} as const;

export const timeSourcesRoutesArray = Object.keys(timeSourcesRoutes) as TimeSourcesKeys[];

export type TimeSourcesKeys = keyof typeof timeSourcesRoutes;
export type TimeSources = typeof timeSourcesRoutes;
