import { ButtonProps, default as CarbonButton } from '@web/components/Shared/Button';
import Loader from 'carbon-react/lib/components/loader';
import styled from 'styled-components';

export interface PublicTextBoxProps extends Omit<ButtonProps, 'children'> {
  label: string;
  loading?: boolean;
}

const ButtonContainer = styled.div`
  width: 100%;

  button {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    border-radius: 22px;
  }
`;

const PublicButton = ({ loading, label, ...props }: PublicTextBoxProps) => {
  return (
    <ButtonContainer>
      <CarbonButton {...props}>{loading ? <Loader isInsideButton /> : <span>{label}</span>}</CarbonButton>
    </ButtonContainer>
  );
};

export default PublicButton;
