import styled, { css } from 'styled-components';

const defaultTitleStyle = css`
  color: ${({ theme }) => theme.palette.blackOpacity(0.9)};
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
`;
const embeddedTitleStyle = css`
  color: ${({ theme }) => theme.palette.blackOpacity(0.9)};
  font-size: 32px;
  font-weight: 500;
  line-height: 125%;
`;

export const PageTitle = styled.span`
  ${({ theme }) => (theme.name === 'SIT-Embeded' ? embeddedTitleStyle : defaultTitleStyle)}
`;
