import { initializeAuthCallbacks, initializeAxiosRequestInterceptor } from '@sit/client-shared';
import { requestLogout } from '@web/helpers/api';
import { useAppDispatch, useAppSelector } from '@web/redux/store';
import { useEffect } from 'react';
import { useUpdateAccessToken } from './access-token';

export function useInitializeClientShared() {
  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();
  const setToken = useUpdateAccessToken();
  const refreshToken = useAppSelector((state) => state.user.refreshToken);
  useEffect(() => {
    initializeAuthCallbacks({
      getToken: () => token || undefined,
      getRefreshToken: () => refreshToken || undefined,
      setToken: (token) => {
        void setToken(token);
      },
      onLogout: () => {
        dispatch(requestLogout());
      },
    });
  }, [token, refreshToken, setToken, dispatch]);
  useEffect(() => {
    initializeAxiosRequestInterceptor();
  }, []);
}
