import { toast, ToastOptions, TypeOptions } from 'react-toastify';

import CarbonToast, { ToastType } from '../components/ToastComponent';

interface ToastrOptions extends ToastOptions {
  dismissToastId: 'ALL' | string | string[];
}

const toTypeOption = (toastType: ToastType): TypeOptions => {
  switch (toastType) {
    case 'neutral':
      return 'default';
    default:
      return toastType as TypeOptions;
  }
};

const renderToast = (toastType: ToastType, msg: string): JSX.Element => <CarbonToast variant={toastType} message={msg} />;

const buildToast = (toastType: ToastType, msg: string, options: Partial<ToastrOptions> = {}) => {
  const toastId = options.toastId || msg;
  const { dismissToastId } = options;

  if (dismissToastId) {
    if (dismissToastId === 'ALL') {
      toast.dismiss();
    } else if (typeof dismissToastId === 'string') {
      toast.dismiss(dismissToastId);
    } else if (Array.isArray(dismissToastId)) {
      dismissToastId.forEach((id) => toast.dismiss(id));
    }
  }

  if (toast.isActive(toastId)) {
    toast.update(toastId, {
      ...options,
      render: renderToast(toastType, msg),
      type: toTypeOption(toastType),
      toastId,
    });
  } else {
    return toast(renderToast(toastType, msg), { ...options, toastId });
  }
};

const error = (msg: string, options: Partial<ToastrOptions> = {}) => {
  if (msg.includes('Two-Factor authentication is required')) return;

  return buildToast('error', msg, { autoClose: 8000, ...options });
};

const info = (msg: string, options: Partial<ToastrOptions> = {}) => {
  return buildToast('info', msg, { autoClose: 5000, ...options });
};

const success = (msg: string, options: Partial<ToastrOptions> = {}) => {
  return buildToast('success', msg, { autoClose: 5000, ...options });
};

const warning = (msg: string, options: Partial<ToastrOptions> = {}) => {
  return buildToast('warning', msg, { autoClose: 5000, ...options });
};

const toastr = {
  error,
  info,
  success,
  warning,
};

export default toastr;
