import { useCanListMyTimesheets, useHasStaffTimesheets } from '@sit/client-shared';
import { useIsAuthenticated } from '@web/redux/hooks';
import { useNavigate } from 'react-router-dom';

export function useNavigateHome() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const mt = useCanListMyTimesheets({ enabled: isAuthenticated });
  const st = useHasStaffTimesheets({ enabled: isAuthenticated });
  if (mt) return () => navigate('/timesheets');
  if (st) return () => navigate('/staff-timesheets');
  return () => navigate('/');
}

export function useNavigateBack() {
  const navigate = useNavigate();
  const navigateHome = useNavigateHome();
  const hasHistory: boolean = window.history.state && window.history.state.idx > 0;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  if (hasHistory) return (toFallback?: string) => navigate(-1);
  return (toFallback?: string) => (toFallback ? navigate(toFallback) : navigateHome());
}
