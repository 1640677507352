import React from 'react';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import CarbonProvider from 'carbon-react/lib/components/carbon-provider';
import CarbonGlobalStyle from 'carbon-react/lib/style/global-style';
import SitGlobalStyle from '../global-style';

import { useTheme } from './hooks';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const theme = useTheme();

  return (
    <CarbonProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <CarbonGlobalStyle />
        <SitGlobalStyle />
        {children}
      </StyledThemeProvider>
    </CarbonProvider>
  );
};
