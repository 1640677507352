import BaseDialog, { DialogProps as BaseDialogProps } from 'carbon-react/lib/components/dialog';
import styled, { css } from 'styled-components';

const dialogDefaultStyle = css<{ complexForm?: boolean }>`
  & [data-component='dialog'] {
    background-color: ${({ theme, complexForm }) => (complexForm ? theme.palette.slateTint(95) : theme.colors.white)};
  }
`;
const dialogEmbeddedStyle = css<{ complexForm?: boolean }>`
  & [data-component='dialog'] {
    background-color: ${({ theme, complexForm }) => (complexForm ? theme.palette.slateTint(95) : theme.colors.white)};
    & > div:first-child {
      padding: ${({ theme }) => `${theme.space[2]} ${theme.space[3]}`};
      & h1 {
        font-size: 20px;
        line-height: 125%;
        font-weight: 500;
        padding: 0;
      }
      & > div {
        margin-bottom: 0;
      }
    }

    & > button[data-element='close'] {
      right: 24px;
      top: 18px;
    }

    & > div:nth-child(3) {
      ${({ theme, complexForm }) => (complexForm ? null : `padding: 0 ${theme.space[3]} ${theme.space[3]} ${theme.space[3]}`)};
    }
  }
`;

const StyledDialog = styled(BaseDialog)<{ complexForm?: boolean }>`
  ${({ theme }) => (theme.name === 'SIT-Embeded' ? dialogEmbeddedStyle : dialogDefaultStyle)}
`;

export interface DialogProps extends BaseDialogProps {
  complexForm?: boolean;
}

const Dialog = ({ complexForm, ...props }: DialogProps) => {
  return <StyledDialog complexForm={complexForm} {...props} />;
};

export default Dialog;
