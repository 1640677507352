import { AnyAction, Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TimesheetStatusStore } from '../types/redux/stores/timesheetStatus.type';
import { logout } from './authentication-slice';

type FilterAction = (action: AnyAction) => boolean;

const matchAction =
  (filter: FilterAction = () => true): FilterAction =>
  (action: AnyAction) => {
    const { type } = action;
    const isGetAction = type.startsWith('GET');

    return !isGetAction && filter(action);
  };

const isUpdatingAction = matchAction(({ type }) => type.endsWith('REQUEST'));
const isUpdatingActionResponse = matchAction(({ type }) => type.endsWith('SUCCESS') || type.endsWith('FAILURE'));

const addReducer = (state: Draft<TimesheetStatusStore>, name: string) => {
  const actionCounter = state.pendingResponses[name] ?? 0;

  state.pendingResponses[name] = actionCounter + 1;
};

const removeReducer = (state: Draft<TimesheetStatusStore>, name: string) => {
  const actionCounter = state.pendingResponses[name] ?? 0;

  const finalCount = actionCounter - 1;

  if (finalCount > 0) {
    state.pendingResponses[name] = finalCount;
  } else {
    delete state.pendingResponses[name];
  }
};

const initialState: TimesheetStatusStore = {
  pendingResponses: {},
};

const timesheetStatusSlice = createSlice({
  name: 'timesheetStatus',
  initialState,
  reducers: {
    addRequest: (state, action: PayloadAction<{ name: string }>) => addReducer(state, action.payload.name),
    removeRequest: (state, action: PayloadAction<{ name: string }>) => removeReducer(state, action.payload.name),
  },
  extraReducers: (builder) => {
    builder.addCase(logout.type, () => initialState);
    builder.addMatcher(isUpdatingAction, (state, action) => addReducer(state, action.type));
    builder.addMatcher(isUpdatingActionResponse, (state, action) => removeReducer(state, action.type.replace(/_(SUCCESS|FAILURE)/, '')));
  },
});

export const { addRequest, removeRequest } = timesheetStatusSlice.actions;
export const reducer = timesheetStatusSlice.reducer;
