import { DataSoucesHeader } from './TimeSoucesHeader';

import { Trans } from '@lingui/macro';
import Preview from 'carbon-react/lib/components/preview';
import Typography from 'carbon-react/lib/components/typography';
import styled, { css } from 'styled-components';
import { SourceCard } from './SourceCard';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing * 3}px;
`;

const timeSourcesTitleDefaultStyle = css`
  color: ${({ theme }) => theme.palette.blackOpacity(0.9)};
  font-size: 24px;
  font-weight: 700;
`;
const timeSourcesTitleEmbeddedStyle = css`
  color: ${({ theme }) => theme.palette.blackOpacity(0.9)};
  font-size: 20px;
  font-weight: 500;
`;

const TimeSourcesTableTitle = styled(Typography)`
  ${({ theme }) => (theme.name === 'SIT-Embeded' ? timeSourcesTitleEmbeddedStyle : timeSourcesTitleDefaultStyle)}
`;

const SourcesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space[3]};
  max-width: 850px;
  width: 100%;
`;

const TableContent = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.palette.slateTint(85)};
  padding: ${({ theme }) => theme.spacing * 3}px;
  border-radius: ${({ theme }) => theme.spacing / 2}px;
  overflow-x: auto;
`;

function TimeSourcesPreview() {
  return (
    <Root>
      <DataSoucesHeader />
      <SourcesList>
        <SourceCard title={<Preview />} description={<Preview />} buttonLabel={<Preview width="100px" />} disabled />
        <SourceCard title={<Preview />} description={<Preview />} buttonLabel={<Preview width="100px" />} disabled />
        <SourceCard title={<Preview />} description={<Preview />} buttonLabel={<Preview width="100px" />} disabled />
      </SourcesList>
      <TableContent>
        <TimeSourcesTableTitle>
          <Trans>Connected time sources</Trans>
        </TimeSourcesTableTitle>
        <Preview height="300px" />
      </TableContent>
    </Root>
  );
}

export default TimeSourcesPreview;
