import { GetApiAccountsResponse, GetApiDataStatusResponse, useGetApiAccounts, useGetApiDataStatus } from '@sit/client-shared';
import { timeSourcesDefaultQueryParams } from '../constants';

type LoadingTimeSources = {
  loading: true;
};

type LoadedTimeSources = {
  loading: false;
  isEmpty: boolean;
  sources: GetApiDataStatusResponse;
  accounts: GetApiAccountsResponse;
};

type UseTimeSources = LoadingTimeSources | LoadedTimeSources;

function useTimeSources(): UseTimeSources {
  const sourcesRequest = useGetApiDataStatus({}, timeSourcesDefaultQueryParams);
  const accountsRequest = useGetApiAccounts({}, timeSourcesDefaultQueryParams);

  if (sourcesRequest.isLoading || accountsRequest.isLoading) {
    return {
      loading: true,
    };
  }

  if (sourcesRequest.error) {
    throw sourcesRequest.error;
  }

  if (accountsRequest.error) {
    throw accountsRequest.error;
  }

  const sources = sourcesRequest.data;
  const accounts = accountsRequest.data;

  const isEmpty = accounts.length === 0 && sources.albert === 'albert_not_yet_synced';

  return {
    loading: false,
    isEmpty,
    sources,
    accounts,
  };
}

export default useTimeSources;
