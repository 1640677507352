import Preview from 'carbon-react/lib/components/preview';
import styled from 'styled-components';

const Root = styled.div`
  min-width: 423px;
`;

const PreviewComponent = () => {
  return (
    <Root>
      <Preview height="50px" loading />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '5%',
          paddingTop: '24px',
        }}
      >
        <div style={{ marginBottom: 24 }}>
          <Preview height="400px" loading />
        </div>
        <div style={{ marginBottom: 24 }}>
          <Preview height="400px" loading />
        </div>
        <div style={{ marginBottom: 24 }}>
          <Preview height="400px" loading />
        </div>
        <div style={{ marginBottom: 24 }}>
          <Preview height="400px" loading />
        </div>
      </div>
    </Root>
  );
};

export default PreviewComponent;
