import { Trans } from '@lingui/macro';
import Typography from 'carbon-react/lib/components/typography';
import styled, { css } from 'styled-components';
import Preview from './Preview';
import SourcesTable from './SourcesTable';
import { DataSoucesHeader } from './TimeSoucesHeader';
import TimeSourceCards from './TimeSourceCards';
import { DataSourcesZeroState } from './TimeSourcesZeroState';
import useTimeSources from './hooks/useTimeSources';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing * 3}px;
  margin-bottom: ${({ theme }) => theme.spacing * 4}px;
`;

const timeSourcesTitleDefaultStyle = css`
  color: ${({ theme }) => theme.palette.blackOpacity(0.9)};
  font-size: 24px;
  font-weight: 700;
`;
const timeSourcesTitleEmbeddedStyle = css`
  color: ${({ theme }) => theme.palette.blackOpacity(0.9)};
  font-size: 20px;
  font-weight: 500;
`;

const TimeSourcesTableTitle = styled(Typography)`
  ${({ theme }) => (theme.name === 'SIT-Embeded' ? timeSourcesTitleEmbeddedStyle : timeSourcesTitleDefaultStyle)}
`;

const TableContent = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.palette.slateTint(85)};
  padding: ${({ theme }) => theme.spacing * 3}px;
  border-radius: ${({ theme }) => theme.spacing / 2}px;
  overflow-x: auto;
`;

function TimeSources() {
  const timeSources = useTimeSources();

  if (timeSources.loading) {
    return <Preview />;
  }

  const { sources, accounts, isEmpty } = timeSources;

  return (
    <Root>
      <DataSoucesHeader />
      <TimeSourceCards />
      <TableContent>
        <TimeSourcesTableTitle>
          <Trans>Connected time sources</Trans>
        </TimeSourcesTableTitle>
        {isEmpty ? <DataSourcesZeroState /> : <SourcesTable accounts={accounts} albert={sources.albert} />}
      </TableContent>
    </Root>
  );
}

export default TimeSources;
