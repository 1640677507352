import { useGetCompany } from '@sit/client-shared';
import { useCurrentEntity } from '@web/hooks/use-current-entity';
import { useIsAuthenticated } from '@web/redux/hooks';

export function useGetCurrentCompany() {
  const isAuthenticated = useIsAuthenticated();
  const companyId = useCurrentEntity()?.id;

  return useGetCompany(
    {
      queryParams: {
        companyId,
      },
    },
    { enabled: isAuthenticated && !!companyId },
  );
}
