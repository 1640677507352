import { Trans } from '@lingui/macro';
import { TimesheetType } from '@sit/client-shared';
import Typography from 'carbon-react/lib/components/typography';
import styled from 'styled-components';
import { TimesheetListItem } from './TimesheetListItem';

const ListContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.slateTint(85)};
  height: 100%;
  max-height: 400px;
  overflow: auto;
  padding: ${({ theme }) => theme.space[2]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[1]};
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface TimesheetsListProps {
  timesheets: Pick<TimesheetType, 'startDate' | 'endDate' | 'id' | 'description'>[];
}
export const TimesheetsList = ({ timesheets }: TimesheetsListProps) => {
  return (
    <Root>
      <Typography variant="strong" display="block">
        <Trans>Selected timesheets:</Trans>
      </Typography>
      <ListContainer>
        {timesheets.map((timesheet) => (
          <TimesheetListItem key={timesheet.id} timesheet={timesheet} />
        ))}
      </ListContainer>
    </Root>
  );
};
