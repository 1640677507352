import { useTheme } from '@web/styles/theme/hooks';

export type OverridableButtonTypes = 'embedded' | 'test';

export type EnhancedButtonTypeObject<T extends string> = { default: T } & Partial<{
  [key in OverridableButtonTypes]: T;
}>;

export type EnhancedButtonType<T extends string> = T | EnhancedButtonTypeObject<T>;

export const useEnhancedButtonType = <T extends string>(type?: EnhancedButtonType<T>): T | undefined => {
  const { name } = useTheme();

  if (!type) {
    return;
  }

  if (typeof type === 'string') {
    return type;
  }

  if (name === 'SIT-Embeded') {
    return type.embedded;
  }

  return type.default;
};
