import { setBaseUrl } from '@sit/client-shared';

let backendOrigin = import.meta.env.VITE_APP_API_URL || '';

export function updateBackendOrigin(origin: string): void {
  backendOrigin = origin;
  setBaseUrl(origin);
}

const VITE_APP_ADMIN_URL = import.meta.env.VITE_APP_ADMIN_URL || 'https://admin.time.sage.com';
const VITE_APP_DIMENSION_SYNC_TIMEOUT = import.meta.env.VITE_APP_DIMENSION_SYNC_TIMEOUT || '5';
const VITE_APP_IS_PRODUCTION = import.meta.env.VITE_APP_IS_PRODUCTION;
const VITE_APP_MINUTES_TO_REFRESH_CPTI = import.meta.env.VITE_APP_MINUTES_TO_REFRESH_CPTI || '5';
const VITE_APP_ROW_CHECKS_MIN = import.meta.env.VITE_APP_ROW_CHECKS_MIN || '2';
const VITE_APP_SUPPORTED_BROWSERS_LINK = import.meta.env.VITE_APP_SUPPORTED_BROWSERS_LINK;

export const config = {
  get apiUrl() {
    return backendOrigin;
  },
  get graphqlUrl() {
    return `${this.apiUrl}/graphql`;
  },
  get subscriptionsUrl() {
    return `${this.apiUrl.replace('http', 'ws')}/subscriptions`;
  },
  isProduction: VITE_APP_IS_PRODUCTION !== undefined,
  isCypress: import.meta.env.VITE_APP_ENV === 'cypress',
  VITE_APP_ADMIN_URL,
  VITE_APP_DIMENSION_SYNC_TIMEOUT: Number.parseInt(VITE_APP_DIMENSION_SYNC_TIMEOUT),
  VITE_APP_MINUTES_TO_REFRESH_CPTI: Number.parseInt(VITE_APP_MINUTES_TO_REFRESH_CPTI),
  VITE_APP_ROW_CHECKS_MIN: Number.parseInt(VITE_APP_ROW_CHECKS_MIN),
  VITE_APP_SUPPORTED_BROWSERS_LINK,
} as const;
