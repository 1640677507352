// https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage

import { reportError } from '../helpers/newrelic';
import { persistConfig } from './configureStore';

type NameEnum = 'user' | 'entities' | 'general';

const LSKey = `persist:${persistConfig.key}`;

const loadLSItem = (name: NameEnum): unknown => {
  try {
    const serializedItem = localStorage.getItem(LSKey);
    if (serializedItem === null) return undefined;
    const parsed = JSON.parse(serializedItem);
    return parsed[name];
  } catch (error: any) {
    reportError(error, {
      type: 'LOCAL_STORAGE_GETITEM',
    });
    return undefined;
  }
};

export const loadUser = (): undefined | any => loadLSItem('user');
