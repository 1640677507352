import { useGetUser } from '@sit/client-shared';
import { useIsAuthenticated } from '@web/redux/hooks';

export function useSelectCompanyInfo() {
  const isAuthenticated = useIsAuthenticated();
  const { data: user } = useGetUser({
    enabled: isAuthenticated,
  });
  const entities = user?.companies;
  const defaultEntityId = user?.defaultCompanyId;
  return { entities, defaultEntityId };
}
