import { Trans } from '@lingui/macro';
import Typography from 'carbon-react/lib/components/typography';
import styled from 'styled-components';
import SageLogo from '../../../assets/img/Logo.svg?react';
import Notifications from '../../../components/Notifications';
import ProfileMenu, { ProfileMenuActions } from '../ApplicationNav/ProfileMenu';
import { MultiEntityButton } from '../MultiEntityButton';
import { HelpButton } from './HelpButton';

const Root = styled.div`
  background-color: ${({ theme }) => theme.palette.black};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 42px;
  padding: 0 ${({ theme }) => theme.spacing * 2}px;
  z-index: ${({ theme }) => theme.zIndex.aboveAll + 2};

  @media screen and (min-width: 1280px) {
    padding: 0 ${({ theme }) => theme.spacing * 13}px;
  }
`;

const LeftContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  min-width: 300px;
  gap: ${({ theme }) => theme.space[2]};
`;

const RightContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[2]};
`;

const ProductTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
  font-family:
    sage text,
    helvetica neue,
    Roboto,
    Segoe UI,
    Arial;
  font-size: 16px;
  font-weight: 700;
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.palette.blackTint(37)};
  border-radius: 5px;
  height: 25px;
  width: 1px;
`;

interface ApplicationNavProps {
  isAuthenticated: boolean;
  onAction: (action: ProfileMenuActions) => void;
}

export const ApplicationNav = ({ isAuthenticated, onAction }: ApplicationNavProps) => {
  return (
    <Root>
      <LeftContent>
        <SageLogo />
        <Divider />
        <ProductTitle mb={0}>
          <Trans>Intelligent Time</Trans>
        </ProductTitle>
        {isAuthenticated && <MultiEntityButton />}
      </LeftContent>
      <RightContent>
        {isAuthenticated && (
          <>
            <Notifications />
            <HelpButton />
          </>
        )}
        <ProfileMenu authenticated={isAuthenticated} onAction={onAction} />
      </RightContent>
    </Root>
  );
};
