export const timePreferencesRoute = '/time-preferences';

export const timePreferenceRoutes = {
  timeGathering: `${timePreferencesRoute}/time-gathering`,
  notifications: `${timePreferencesRoute}/notifications`,
  security: `${timePreferencesRoute}/security`,
  userManagement: `${timePreferencesRoute}/user-management`,
  advanced: `${timePreferencesRoute}/advanced`,
  downloadApp: `${timePreferencesRoute}/download-app`,
  demoPreferences: `${timePreferencesRoute}/demo-settings`,
} as const;

export const timePreferencesDefaultRoute = timePreferenceRoutes.timeGathering;
export const timePreferenceRoutesArray = Object.keys(timePreferenceRoutes) as TimePreferencesKeys[];

export type TimePreferencesKeys = keyof typeof timePreferenceRoutes;
export type TimePreferences = typeof timePreferenceRoutes;
