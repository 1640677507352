import Heading from 'carbon-react/lib/components/heading';
import Preview from 'carbon-react/lib/components/preview';
import styled from 'styled-components';

const Root = styled.div`
  height: 100%;
  padding: ${({ theme }) => theme.spacing * 3.25}px ${({ theme }) => theme.spacing * 4}px;
  width: 100%;
`;

interface PreviewTabProps {
  title: string;
}

const PreviewTab = ({ title }: PreviewTabProps) => {
  return (
    <Root>
      <Heading titleId="settings-tab-title" separator subheader={<Preview loading />} title={title} />
      <Preview height="60px" width="100%" loading />
    </Root>
  );
};

export default PreviewTab;
