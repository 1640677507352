import Preview from 'carbon-react/lib/components/preview';
import styled from 'styled-components';
import { SourceCard } from './SourceCard';
import useSourceCards from './hooks/useSourceCards';

const SourcesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space[3]};
  max-width: 850px;
  width: 100%;
`;

function TimeSourceCards() {
  const { isLoading, sourceCards } = useSourceCards();

  if (isLoading) {
    return (
      <SourcesList>
        <SourceCard title={<Preview />} description={<Preview />} buttonLabel={<Preview width="100px" />} disabled />
        <SourceCard title={<Preview />} description={<Preview />} buttonLabel={<Preview width="100px" />} disabled />
        <SourceCard title={<Preview />} description={<Preview />} buttonLabel={<Preview width="100px" />} disabled />
      </SourcesList>
    );
  }

  return (
    <SourcesList>
      {sourceCards?.map((source) => (
        <SourceCard key={source.title} {...source} />
      ))}
    </SourcesList>
  );
}

export default TimeSourceCards;
