import { useEffect } from 'react';

export class NotFoundError extends Error {
  constructor() {
    super('Not found');
  }
}

export const isNotFoundError = (error: unknown): error is NotFoundError => {
  return error instanceof NotFoundError;
};

export const useNotFound = (predicate: () => boolean) => {
  useEffect(() => {
    if (predicate()) {
      throw new NotFoundError();
    }
  }, [predicate]);
};
