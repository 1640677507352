import { LegacyRequestArguments, NoDataRequest, RequestArguments, RequestConfig, axiosInstance } from '@sit/client-shared';
import { logout } from '@web/redux/authentication-slice';

export const requestLogout = () => ({
  type: logout.type,
  data: {
    dontCheckForUnauthorized: true,
  },
});

class ApiRequest {
  async request<T = unknown, D = unknown>(requestArguments: RequestArguments<D>): Promise<T> {
    const { method, path, data, params, headers, skipToast, dontCheckForUnauthorized } = requestArguments;

    const { data: body } = await axiosInstance.request<T>({
      method,
      url: path,
      headers,
      params,
      data,
      noRetry: dontCheckForUnauthorized,
      skipToast,
    });
    return body;
  }

  /**
   * @deprecated use `get`, `post`, `put`, `delete` instead or `request` in case of custom implementation.
   */
  async send<T = unknown>(requestArguments: LegacyRequestArguments): Promise<T> {
    const { url, ...request } = requestArguments;

    return await this.request({ ...request, path: url });
  }

  async get<T>({ path, params, headers, skipToast }: NoDataRequest): Promise<T> {
    return await this.request({
      method: 'GET',
      path,
      params,
      headers,
      skipToast,
    });
  }

  async post<T = unknown, D = unknown>({ path, params, data, headers, skipToast }: RequestConfig<D>): Promise<T> {
    return await this.request({
      method: 'POST',
      path,
      params,
      data,
      headers,
      skipToast,
    });
  }

  async put<T = unknown, D = unknown>({ path, params, data, headers, skipToast }: RequestConfig<D>): Promise<T> {
    return await this.request({
      method: 'PUT',
      path,
      params,
      data,
      headers,
      skipToast,
    });
  }

  async delete<T = unknown>({ path, params, headers, skipToast }: NoDataRequest): Promise<T> {
    return await this.request({
      method: 'DELETE',
      path,
      params,
      headers,
      skipToast,
    });
  }
}

const apiClient = new ApiRequest();

export default apiClient;
