import { PropsWithChildren } from 'react';
import MaintenanceModeComponent from './MaintenanceModeComponent';

import { useFlag } from '@web/hooks';

const MaintenanceMode = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const maintenanceMode = useFlag('MAINTENANCE_SCREEN');

  if (maintenanceMode?.active === true && maintenanceMode?.time) {
    return <MaintenanceModeComponent time={maintenanceMode.time} />;
  } else {
    return <>{children}</>;
  }
};

export default MaintenanceMode;
