import { CompanyType } from '@sit/client-shared';
import moment, { Moment } from 'moment';

interface MonthRemainingHalf {
  startDate: Moment;
  endDate: Moment;
}
export const getMonthRemainingHalf = (date: Moment = moment()): MonthRemainingHalf => {
  const todayDate = date.clone();
  const today = todayDate.date();

  const monthStart = todayDate.startOf('month').clone();
  const monthEnd = todayDate.endOf('month').clone();

  if (today <= 15) {
    return {
      startDate: monthStart.clone(),
      endDate: monthStart.clone().add(14, 'days'),
    };
  }

  return {
    startDate: monthStart.clone().add(15, 'days'),
    endDate: monthEnd.clone(),
  };
};

export const getStartOfWeek = (weekStart: number, todayDate: Moment = moment()): Moment => {
  const result = todayDate
    .clone()
    .startOf('week')
    .day(weekStart - 1);
  if (result.isAfter(todayDate)) {
    result.subtract(1, 'week');
  }
  return result;
};

export const getEndDate = (length: number, todayDate: Moment = moment()): Moment => {
  return todayDate.clone().add(length - 1, 'days');
};

export const getTimesheetEndDate = (company: Pick<CompanyType, 'timesheetDuration'>, startDate: Moment = moment()): Moment => {
  const { timesheetDuration } = company;

  if (timesheetDuration === 15) {
    const { endDate } = getMonthRemainingHalf(startDate);

    return endDate;
  }

  return getEndDate(timesheetDuration, startDate);
};
