import { useGetUser } from '@sit/client-shared';
import { useIsAuthenticated } from '@web/redux/hooks';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

export function useInitializeLaunchDarklyIdentifier() {
  const isAuthenticated = useIsAuthenticated();
  const { data: userData } = useGetUser({
    enabled: isAuthenticated,
  });
  const ldClient = useLDClient();

  // By using ldClient above, the app does not re-rendered when the user is updated (login, logout)
  useEffect(() => {
    if (isAuthenticated && userData?.id) {
      const ldUser = { key: userData.id, custom: userData.launchDarklyPayload };
      void ldClient?.identify(ldUser).catch();
    } else {
      void ldClient
        ?.identify({
          key: 'ANONYMOUS_USER',
          anonymous: true,
        })
        .catch();
    }
  }, [isAuthenticated, userData, ldClient]);

  // We only want to use the LaunchDarkly Client to identify the user. The provider is created before the whole app is rendered.
}
