import Message, { type MessageProps } from 'carbon-react/lib/components/message';

export type ToastType = MessageProps['variant'];

export interface CarbonToastProps {
  closeToast?: () => void;
  variant: ToastType;
  message: string;
}

const CarbonToast = ({ closeToast, variant, message }: CarbonToastProps) => {
  return (
    <Message className="carbon-toast" onDismiss={closeToast} open variant={variant}>
      {message}
    </Message>
  );
};

export default CarbonToast;
