import Help from 'carbon-react/lib/components/help';
import styled from 'styled-components';

const Root = styled.div`
  padding-top: ${({ theme }) => theme.space[3]};
`;

interface FieldHelpProps {
  children: React.ReactNode;
}

export const FieldHelp = ({ children }: FieldHelpProps) => (
  <Root>
    <Help>{children}</Help>
  </Root>
);
