import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AuthenticationStore {
  token: string | null;
  refreshToken: string | null;
}

const initialState: AuthenticationStore = {
  token: null,
  refreshToken: null,
};

const authenticationSlice = createSlice({
  // For backwards compatibility with existing `redux-persist` configuration. Would prefer `authentication` as the name.
  name: 'user',
  initialState,
  reducers: {
    setAuthTokens(state, action: PayloadAction<Pick<AuthenticationStore, 'token' | 'refreshToken'>>) {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    logout(state) {
      state.token = null;
      state.refreshToken = null;
    },
  },
});

export const { setAccessToken, setAuthTokens, logout } = authenticationSlice.actions;
export default authenticationSlice.reducer;
