import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useGetUser, type CompanyType } from '@sit/client-shared';
import { useGetCurrentCompany } from '@web/api/company/use-get-company';
import { useGetEmployees } from '@web/api/employees/use-get-employees';
import Button from '@web/components/Shared/Button';
import DateInput from '@web/components/Shared/DateInput';
import Dialog from '@web/components/Shared/Dialog';
import Box from 'carbon-react/lib/components/box';
import { Checkbox } from 'carbon-react/lib/components/checkbox';
import { type DateChangeEvent } from 'carbon-react/lib/components/date';
import Form from 'carbon-react/lib/components/form';
import { FilterableSelect, Option, type CustomSelectChangeEvent } from 'carbon-react/lib/components/select';
import Textarea from 'carbon-react/lib/components/textarea';
import Typography from 'carbon-react/lib/components/typography';
import moment, { type Moment } from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import { TimesheetStartDayValidator } from '../../../helpers/validations';
import { getTimesheetEndDate } from '../helpers/timesheets';
import { FieldHelp } from './FieldHelp';

const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};
`;

const DateInputContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[2]};
`;

const SelectContent = styled.div`
  width: 344px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[1]};
`;
const SelectLabel = styled(Typography)`
  font-weight: 600;
`;

const DescriptionField = styled.div`
  width: 344px;
`;

const FieldBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[1]};
  align-items: center;
`;

const TIME_FORMAT = 'YYYY-MM-DD';

export interface FormData {
  startDate: Moment;
  endDate: Moment;
  description: string;
  employeeId?: string;
  includeEntries?: boolean;
}

interface CreateTimesheetModalProps {
  title?: string;
  data: FormData;
  showIncludeEntriesBox?: boolean;
  submitButtonText?: string;
  staff?: boolean;
  onClose: () => void;
  onSubmit: (data: FormData) => void;
}

const validateStartDate = (company: CompanyType, startDate: Moment) => {
  const validation = new TimesheetStartDayValidator(company);
  if (!validation.validate(startDate.format(TIME_FORMAT))) {
    return validation.message();
  }
  return null;
};

export const CreateTimesheetModal = ({
  title,
  data,
  showIncludeEntriesBox = false,
  onClose,
  onSubmit,
  submitButtonText,
  staff,
}: CreateTimesheetModalProps) => {
  const { _ } = useLingui();
  const [formData, setFormData] = useState<FormData>(data);
  const { startDate, endDate, description, includeEntries, employeeId } = formData;
  const { data: emloyeesData } = useGetEmployees();
  const { data: userData } = useGetUser();
  const { data: company } = useGetCurrentCompany();

  if (!company) {
    return null;
  }

  const updateFieldValue = (field: keyof FormData, value: FormData[typeof field]) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const onStartDateFieldChange = (ev: DateChangeEvent) => {
    const { rawValue } = ev.target.value;

    const value = moment.utc(rawValue, TIME_FORMAT);

    updateFieldValue('startDate', value);

    const newEndDate = getTimesheetEndDate(company, value);

    updateFieldValue('endDate', newEndDate);
  };

  const updateTextField =
    (field: keyof FormData) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      updateFieldValue(field, target.value);
    };

  const handleEmployeeChange = (event: CustomSelectChangeEvent) => {
    // `event.selectionConfirmed` is a custom property in the `FilterableSelect` component's `onChange` callback
    // to indicate that the user has selected an option.
    // We don't want to call the component callback's `onChange` if the user has not selected an option.
    // Without this check, every time the user types a character, the we will call `updateFieldValue`
    // with the value to the first item in the dropdown list.

    // In other instances of `FilterableSelect`, we want to call the `onChange` callback if the user has cleared the input field.
    // However, in this case, since we always require an employee, we don't want to call the `onChange` callback if the user has cleared the input field.
    if (event.target.value == null || event.selectionConfirmed === false) return;
    return updateFieldValue('employeeId', event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  const invalidStartDate = validateStartDate(company, startDate);

  const employee = emloyeesData?.employees;
  const employeeSelectOptions = (() => {
    const options = employee && employee.length > 0 ? employee : [{ employeeId: userData?.employeeId ?? '', name: userData?.name ?? '' }];
    return options.map((c) => ({ text: c.name, value: c.employeeId }));
  })();

  return (
    <Dialog
      disableAutoFocus
      disableEscKey={false}
      enableBackgroundUI={false}
      onCancel={() => onClose()}
      open
      showCloseIcon
      title={title ?? _(msg`Create timesheet`)}
      data-cy="create-timesheet-modal"
    >
      <Form
        saveButton={
          <Button
            data-pendo-id="create-timesheet-confirm"
            buttonType={{
              default: 'primary',
              embedded: 'secondary',
            }}
            onClick={handleSubmit}
            disabled={!!invalidStartDate}
            ml={1}
            data-cy="create-timesheet-create-timesheet-button"
          >
            {submitButtonText ?? <Trans>Create timesheet</Trans>}
          </Button>
        }
        leftSideButtons={
          <Button
            data-pendo-id="create-timesheet-cancel"
            buttonType={{
              default: 'tertiary',
              embedded: 'secondary',
            }}
            onClick={onClose}
            data-cy="create-timesheet-cancel-button"
          >
            <Trans>Cancel</Trans>
          </Button>
        }
        fieldSpacing={0}
      >
        <InputContent>
          <DateInputContent>
            <FieldBox data-cy="create-timesheet-begin-date-label">
              <DateInput
                allowEmptyValue
                error={invalidStartDate || undefined}
                label={_(msg`Begin date`)}
                name="start-date-input"
                value={startDate.format(TIME_FORMAT)}
                onChange={onStartDateFieldChange}
                mb={0}
              />
              <FieldHelp>
                <Trans>
                  Select the date that this timesheet begins. Your company settings determine which day of the week timesheets can start.
                  Admins can change the setting by going to <strong>Company</strong> &gt; <strong>Setup</strong> &gt;{' '}
                  <strong>Company</strong> &gt; <strong>Accounting</strong> and adjusting the <strong>Week begins on</strong> field.
                </Trans>
              </FieldHelp>
            </FieldBox>
            <FieldBox data-cy="create-timesheet-end-date-label">
              <DateInput
                readOnly
                label={_(msg`End date`)}
                name="end-date-input"
                onChange={() => {}}
                value={endDate.format(TIME_FORMAT)}
                mb={0}
              />
              <FieldHelp>
                <Trans>
                  The end date is automatically calculated based on your selected begin date and your company settings. Admins can change
                  the setting by going to <strong>Projects</strong> &gt; <strong>Setup</strong> &gt; <strong>Projects configuration</strong>{' '}
                  and adjusting the <strong>Timesheet duration</strong> field. The duration can be daily, weekly, every two weeks, or twice
                  per month.
                </Trans>
              </FieldHelp>
            </FieldBox>
          </DateInputContent>
          {staff && (
            <SelectContent>
              <SelectLabel mb={0}>Employee</SelectLabel>
              <FilterableSelect placeholder={_(msg`Type to search`)} onChange={handleEmployeeChange} value={employeeId ?? ''}>
                {employeeSelectOptions.map((option) => (
                  <Option key={option.value} value={option.value} text={option.text ?? ''} />
                ))}
              </FilterableSelect>
            </SelectContent>
          )}
          <DescriptionField data-cy="create-timesheet-description-label">
            <Textarea
              autoFocus
              label={_(msg`Description`)}
              name="description"
              onChange={updateTextField('description')}
              value={description}
            />
          </DescriptionField>
          {showIncludeEntriesBox && (
            <Box data-cy="create-timesheet-include-entries-label">
              <Checkbox
                label={_(msg`Include entries`)}
                name="include-entries"
                onChange={() => {
                  updateFieldValue('includeEntries', !includeEntries);
                }}
                checked={includeEntries}
              />
            </Box>
          )}
        </InputContent>
      </Form>
    </Dialog>
  );
};
