import { useNavigateHome } from '@web/hooks/use-navigate';
import { useIsAuthenticated } from '@web/redux/hooks';

function PublicRoute({ children }: { children: JSX.Element }) {
  const isAuthenticated = useIsAuthenticated();
  const navigateHome = useNavigateHome();
  if (isAuthenticated) {
    navigateHome();
    return null;
  }
  return children;
}

export default PublicRoute;
