import { Notification, NotificationGraphQl, NotificationType, PushNotification, useGetNotifications } from '@sit/client-shared';
import { useIsAuthenticated } from '@web/redux/hooks';
import { PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react';
import { useLatestServerNotification } from '../../graphql/subscriptions';
import NotificationHandlerService, { NotificationTopic } from './NotificationHandlerService';
import { toastNotification } from './display-notifications';

const notificationHanderService = new NotificationHandlerService();

interface NotificationContext {
  notifications: NotificationGraphQl[];
  service: NotificationHandlerService;
  markAsRead: () => void;
}

function shouldAddNotificationToPanel(notification: PushNotification): boolean {
  switch (notification.topic) {
    case NotificationTopic.SUBMIT_TIMESHEET:
      return true;
    case NotificationTopic.TIMESHEETS:
      return true;
    default:
      return false;
  }
}

const toMeta = (
  notification: Pick<PushNotification, 'data' | 'topic'>,
): {
  meta?: string;
  metaId?: string;
} | null => {
  switch (notification.topic) {
    case NotificationTopic.SAVE_TIMESHEET:
    case NotificationTopic.SUBMIT_TIMESHEET:
      return {
        meta: notification.topic,
        metaId: notification.data.timesheetId ?? notification.data.metaId,
      };
    case NotificationTopic.TIMESHEETS:
      return {
        meta: notification.topic,
      };
    default:
      return {};
  }
};

const rqToPanelNotification = (notification: Notification): NotificationGraphQl => {
  return {
    __typename: 'NotificationGraphQL',
    id: notification.id,
    message: notification.message,
    notification: {
      body: notification.description ?? '',
      title: (notification.message || notification.title) ?? '',
    },
    pushIsRead: notification.pushIsRead,
    timestamp: notification.createdAt,
    type: notification.type as NotificationType,
    ...toMeta({
      topic: notification.meta,
      data: {
        metaId: notification.metaId,
      },
    }),
  };
};

const toPanelNotification = (notification: PushNotification): NotificationGraphQl => {
  return {
    __typename: 'NotificationGraphQL',
    id: notification.id,
    message: notification.message,
    notification: notification.notification,
    pushIsRead: false,
    timestamp: notification.timestamp,
    type: notification.type,
    ...toMeta(notification),
  };
};

export const NotificationHandlerContext = createContext<NotificationContext>({
  service: notificationHanderService,
  notifications: [],
  markAsRead: () => {},
});

export const NotificationHandlerProvider = ({ children }: PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const [notifications, setNotifications] = useState<NotificationGraphQl[]>([]);
  const isAuthenticated = useIsAuthenticated();

  const { data: response } = useGetNotifications(
    {},
    {
      enabled: isAuthenticated,
    },
  );

  useEffect(() => {
    if (response) {
      setNotifications(response.map((n) => rqToPanelNotification(n)));
    }
  }, [response]);

  const addNotification = useCallback((notification: PushNotification) => {
    if (shouldAddNotificationToPanel(notification)) {
      setNotifications((n) => [toPanelNotification(notification), ...n]);
    }
  }, []);

  const { data } = useLatestServerNotification();

  useEffect(() => {
    if (data) {
      notificationHanderService.handleNotification(data.newNotification);
      toastNotification(data.newNotification);
      addNotification(data.newNotification);
    }
  }, [data, addNotification]);

  const markAsRead = useCallback(() => {
    setNotifications((n) => n.map((notification) => ({ ...notification, pushIsRead: true })));
  }, []);

  return (
    <NotificationHandlerContext.Provider value={{ service: notificationHanderService, notifications, markAsRead }}>
      {children}
    </NotificationHandlerContext.Provider>
  );
};
