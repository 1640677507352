import { WebFeatureFlags } from '@web/launchDarkly/flags';

export enum BuildType {
  default = 'default',
  embedded = 'embedded',
}

export interface DevOverrides {
  dashboardVisible: boolean;
  featureFlags: Partial<WebFeatureFlags>;
}

export const BUILD_TYPES: Readonly<BuildType[]> = Object.values(BuildType);
