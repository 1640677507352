import {
  GetApiDimensionsError,
  GetApiDimensionsQueryParamsUpdated,
  GetApiDimensionsResponse,
  useGetDimensions as useGetApiDimensions,
} from '@sit/client-shared';
import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

/**
 * 1:   [Optional] On load of the **timesheets** page, /api/dimensions?companyId=current-sit-company-uuid
 *      This populates the cache with the dimensions for the company, the "most common" scenario,
 *      resulting in perf improvements.
 * 2.:  On **timesheet** page:
 * 2.1: If you have the companyId associated with the timesheet (stored in Redux, cache, or whatever), use the call /api/dimensions?companyId=SIT-COMPANY-ID
 *      Update the cache with the dimensions for the company: Record<SITCompanyId, DimensionsData>
 * 2.2: If you DON’T have the companyId associated with the timesheet (deep link, page reload), use the call /api/dimensions?timesheetId=SIT-TIMESHEET-ID
 *      Update the cache with the dimensions for the company: Record<SITCompanyId, DimensionsData>
 */
export function useGetDimensions(
  queryParams: GetApiDimensionsQueryParamsUpdated,
  options?:
    | Omit<
        UseQueryOptions<GetApiDimensionsResponse, GetApiDimensionsError, GetApiDimensionsResponse, QueryKey>,
        'initialData' | 'queryFn' | 'queryKey'
      >
    | undefined,
) {
  //   const queryClient = useQueryClient();
  // If passed a `companyId`, we can use it immediately.
  // If passed a `timesheetId`, we first try to get the `companyId` from the cache.
  // If we don't find it in the cache, we DO NOT make a request for it.
  // Instead, we use the `timesheetId` as-is.

  return useGetApiDimensions(queryParams, options);
}
