import { GetApiTimesheetsError, GetApiTimesheetsResponse, useGetTimesheets as useGetApiTimesheets } from '@sit/client-shared';
import { QueryKey } from '@tanstack/query-core';
import { UseQueryOptions } from '@tanstack/react-query';
import { useIncludeEntityData } from '@web/containers/Timesheets/hooks/useIncludeEntityData';
import { useIsStaff } from '@web/containers/Timesheets/hooks/useIsStaff';

export function useGetTimesheets(
  options?:
    | Omit<
        UseQueryOptions<GetApiTimesheetsResponse, GetApiTimesheetsError, GetApiTimesheetsResponse, QueryKey>,
        'queryKey' | 'queryFn' | 'initialData'
      >
    | undefined,
) {
  const staff = useIsStaff();
  const includePrivate = useIncludeEntityData();
  return useGetApiTimesheets(
    {
      queryParams: {
        includePrivate: includePrivate ? 'true' : undefined,
        staff: staff ? 'true' : undefined,
      },
    },
    {
      staleTime: 1000 * 5, // 5 seconds
      ...options,
    },
  );
}
