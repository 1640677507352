import NotFoundPage from '@web/containers/NotFoundPage';
import { Navigate } from 'react-router-dom';
import { usePrivateData } from './hooks';

interface PrivateRouteWapperProps {
  children: JSX.Element;
  paramsToValidate?: Record<string, string>;
}

const PrivateRoute = ({ children, paramsToValidate }: PrivateRouteWapperProps) => {
  const result = usePrivateData({
    paramsToValidate,
  });

  switch (result) {
    case 'UNAUTHENTICATED':
      return <Navigate to="/login" />;
    case 'INVALID_PARAMS':
      return <NotFoundPage />;
    case 'NEEDS_TO_SET_UP_MFA':
      return <Navigate to="/time-preferences/security" />;
    case 'UNPROVISIONED':
      return <Navigate to="/time-preferences/advanced" />;
    case 'AUTHORIZED':
      return children;
  }
};

export default PrivateRoute;
