import BaseButton, { ButtonProps as BaseButtonProps } from 'carbon-react/lib/components/button';
import { ButtonTypes } from 'carbon-react/lib/components/button/button.component';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { EnhancedButtonType, useEnhancedButtonType } from '../hooks';

const defaultStyle = css``;
const embeddedStyle = css`
  min-height: 32px;
`;

const StyledButton = styled(BaseButton)`
  ${({ theme }) => (theme.name === 'SIT-Embeded' ? embeddedStyle : defaultStyle)}
`;

export interface ButtonProps extends Omit<BaseButtonProps, 'buttonType'> {
  buttonType?: EnhancedButtonType<ButtonTypes>;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps & React.RefAttributes<HTMLButtonElement>>(
  ({ buttonType: enhancedButtonType, ...props }, ref) => {
    const buttonType = useEnhancedButtonType(enhancedButtonType);

    return <StyledButton ref={ref} buttonType={buttonType} {...props} />;
  },
);

export default Button;
