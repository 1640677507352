import { createContext, useCallback, useState } from 'react';
import { TimesheetModalType } from './timesheet-modal-type';

export type TimesheetModalState =
  | {
      type: TimesheetModalType.NEW | TimesheetModalType.CLOSED;
    }
  | {
      type: TimesheetModalType.DUPLICATE;
      timesheetId: string;
    }
  | {
      type: TimesheetModalType.BULK_DELETE;
      timesheetIds: string[];
    };

export const TimesheetModalsContext = createContext<{
  state: TimesheetModalState;
  openNewTimesheetModal: () => void;
  openDuplicateTimesheetModal: (timesheetId: string) => void;
  openBulkDeleteTimesheetsModal: (timesheetIds: string[]) => void;
  closeModal: () => void;
}>({
  state: { type: TimesheetModalType.CLOSED },
  openNewTimesheetModal: () => {},
  openDuplicateTimesheetModal: () => {},
  openBulkDeleteTimesheetsModal: () => {},
  closeModal: () => {},
});

interface TimesheetModalsProviderProps {
  children: React.ReactNode;
}

export const TimesheetModalsProvider = ({ children }: TimesheetModalsProviderProps) => {
  const [modalState, setModalState] = useState<TimesheetModalState>({ type: TimesheetModalType.CLOSED });

  const openNewTimesheetModal = useCallback(() => {
    setModalState({ type: TimesheetModalType.NEW });
  }, []);

  const openDuplicateTimesheetModal = useCallback((timesheetId: string) => {
    setModalState({ type: TimesheetModalType.DUPLICATE, timesheetId });
  }, []);

  const openBulkDeleteTimesheetsModal = useCallback((timesheetIds: string[]) => {
    setModalState({ type: TimesheetModalType.BULK_DELETE, timesheetIds });
  }, []);

  const closeModal = useCallback(() => {
    setModalState({ type: TimesheetModalType.CLOSED });
  }, []);

  return (
    <TimesheetModalsContext.Provider
      value={{
        state: modalState,
        openNewTimesheetModal,
        openDuplicateTimesheetModal,
        openBulkDeleteTimesheetsModal,
        closeModal,
      }}
    >
      {children}
    </TimesheetModalsContext.Provider>
  );
};
