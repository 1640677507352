import { loadingChunkRetry } from '@sit/client-shared';
import ErrorBoundary from '@web/components/ErrorBoundary';
import { ErrorFallback } from '@web/containers/EntriesApprovals/Error';
import NotFoundPage from '@web/containers/NotFoundPage';
import { useClockInOutEnabled } from '@web/hooks/use-clock-in-out';
import { lazy, Suspense } from 'react';

const TimeCardsPage = lazy(() => loadingChunkRetry(() => import('@web/containers/TimeCards')));

function TimeCards() {
  const enabled = useClockInOutEnabled();
  if (!enabled) {
    return <NotFoundPage />;
  }
  return (
    <ErrorBoundary fallbackUI={(error) => <ErrorFallback error={error} />}>
      <Suspense fallback={null}>
        <TimeCardsPage />
      </Suspense>
    </ErrorBoundary>
  );
}

export default TimeCards;
