import { Trans } from '@lingui/macro';
import { GetApiAccountsResponse, GetApiDataStatusResponse } from '@sit/client-shared';
import { FlatTable, FlatTableBody, FlatTableHead, FlatTableHeader, FlatTableRow } from 'carbon-react/lib/components/flat-table';
import Typography from 'carbon-react/lib/components/typography';
import styled from 'styled-components';
import CalendarRow from './CalendarRow';
import EmailRow from './EmailRow';
import SageAssistantRow from './SageAssistantRow';

const TableHeadLabel = styled(Typography)`
  font-size: 14px;
`;

interface SourcesTableProps {
  albert: GetApiDataStatusResponse['albert'];
  accounts: GetApiAccountsResponse;
}

const SourcesTable = ({ accounts, albert }: SourcesTableProps) => {
  return (
    <>
      <FlatTable colorTheme="transparent-white" size="extraLarge">
        <FlatTableHead>
          <FlatTableRow>
            <FlatTableHeader>
              <TableHeadLabel mb={0}>
                <Trans>Time source</Trans>
              </TableHeadLabel>
            </FlatTableHeader>
            <FlatTableHeader>
              <TableHeadLabel mb={0}>
                <Trans>Account</Trans>
              </TableHeadLabel>
            </FlatTableHeader>
            <FlatTableHeader>
              <TableHeadLabel mb={0}>
                <Trans>Status</Trans>
              </TableHeadLabel>
            </FlatTableHeader>
            <FlatTableHeader>
              <TableHeadLabel mb={0}>
                <Trans>Status message</Trans>
              </TableHeadLabel>
            </FlatTableHeader>
            <FlatTableHeader>
              <TableHeadLabel mb={0}>
                <Trans>Connection</Trans>
              </TableHeadLabel>
            </FlatTableHeader>
            <FlatTableHeader />
          </FlatTableRow>
        </FlatTableHead>
        <FlatTableBody>
          {accounts.length > 0 &&
            accounts.map((account) => {
              if (account.type === 'CALENDAR') {
                return <CalendarRow account={account} key={account.id} />;
              } else {
                return <EmailRow account={account} key={account.id} />;
              }
            })}
          <SageAssistantRow albert={albert} />
        </FlatTableBody>
      </FlatTable>
    </>
  );
};

export default SourcesTable;
