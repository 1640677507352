import {
  NotificationType,
  useInvalidateGetApprovalEntries,
  useInvalidateGetApprovalTimesheets,
  useInvalidateGetTimesheetRows,
  useInvalidateTimesheet,
  PushNotification,
} from '@sit/client-shared';
import { useTimesheetSaveSettled } from '@web/api/timesheet/use-post-timesheet-save-settled';
import { useTimesheetSubmitSettled } from '@web/api/timesheet/use-post-timesheet-submit-settled';
import { PropsWithChildren, useCallback } from 'react';
import { NotificationTopic } from './NotificationHandlerService';
import { useOnLatestNotification } from './hooks/useLatestNotification';

function useHandleGlobalNotifications() {
  const onSaveSettled = useTimesheetSaveSettled();
  const onSubmitSettled = useTimesheetSubmitSettled();
  const invalidateTimesheetsQuery = useInvalidateGetApprovalTimesheets();
  const invalidateEntriesQuery = useInvalidateGetApprovalEntries();
  const invalidateTimesheetRowsQuery = useInvalidateGetTimesheetRows();
  const invalidateTimesheetQuery = useInvalidateTimesheet();
  const handleApprovals = useCallback(
    (notification: PushNotification) => {
      void invalidateTimesheetsQuery();
      void invalidateEntriesQuery();
      if ('timesheets' in notification.data && Array.isArray(notification.data.timesheets)) {
        for (const timesheetId of notification.data.timesheets) {
          void invalidateTimesheetQuery(timesheetId);
          void invalidateTimesheetRowsQuery(timesheetId);
        }
      }
    },
    [invalidateEntriesQuery, invalidateTimesheetQuery, invalidateTimesheetRowsQuery, invalidateTimesheetsQuery],
  );

  useOnLatestNotification(NotificationTopic.APPROVALS, handleApprovals);
  const handleSave = useCallback((notification: PushNotification) => {
    onSaveSettled(notification.data.timesheetId, notification.type === NotificationType.SUCCESS);
  }, []);
  useOnLatestNotification(NotificationTopic.SAVE_TIMESHEET, handleSave);
  const handleSubmit = useCallback(
    (notification: PushNotification) => {
      onSubmitSettled(notification.data.timesheetId, notification.type === NotificationType.SUCCESS);
      if ('timesheetId' in notification.data) {
        void invalidateTimesheetQuery(notification.data.timesheetId);
      }
    },
    [onSubmitSettled, invalidateTimesheetQuery],
  );
  useOnLatestNotification(NotificationTopic.SUBMIT_TIMESHEET, handleSubmit);
}

export function NotificationGlobalHandler({ children }: PropsWithChildren<Record<string, unknown>>) {
  useHandleGlobalNotifications();
  return <>{children}</>;
}
