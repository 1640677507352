import { loadingChunkRetry } from '@sit/client-shared';
import ErrorBoundary from '@web/components/ErrorBoundary';
import { ErrorComponent } from '@web/containers/TimeApprovals/Error';
import { lazy, Suspense } from 'react';

const TimeApprovalsPage = lazy(() => loadingChunkRetry(() => import('@web/containers/TimeApprovals')));

const EntriesApprovals = () => {
  return (
    <ErrorBoundary fallbackUI={(error) => <ErrorComponent error={error} />}>
      <Suspense fallback={null}>
        <TimeApprovalsPage />
      </Suspense>
    </ErrorBoundary>
  );
};

export default EntriesApprovals;
