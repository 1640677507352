import MaintenanceMessage from '../MaintenanceMessage/MaintenanceMessage';
import Toast from '../ToastContainer';
import UnsupportedBrowser from '../UnsupportedBrowser/UnsupportedBrowser';

/**
 * This component handles modals, dialogs, toast and messages that are rendered on top of the application
 */
const OverlayComponents = () => {
  return (
    <>
      {/* React toastify */}
      <Toast />

      {/* Toast Messages */}
      <MaintenanceMessage />
      <UnsupportedBrowser />
    </>
  );
};

export default OverlayComponents;
