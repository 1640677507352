import { Account } from '@sit/client-shared';

export const interval = import.meta.env.VITE_APP_DATA_SOURCES_INTERVAL
  ? Number.parseInt(import.meta.env.VITE_APP_DATA_SOURCES_INTERVAL, 10)
  : 30000;

export type TimeSourcesKeys = Account['type'] | 'TIME_ASSISTANT';

export const accountTypes: TimeSourcesKeys[] = ['CALENDAR', 'EMAIL', 'TIME_ASSISTANT'];

export const timeSourcesDefaultQueryParams = {
  refetchInterval: interval,
};
