import BackButton from '@web/components/Shared/Button/BackButton';
import Box from 'carbon-react/lib/components/box';
import styled, { css } from 'styled-components';
import { PageTitle } from './PageTitle';

const HeaderEmbeddedExperienceStyle = css`
  background-color: ${({ theme }) => theme.colors.white};
  margin-left: ${({ theme }) => theme.spacing * -2}px;
  margin-right: ${({ theme }) => theme.spacing * -2}px;
  margin-top: ${({ theme }) => theme.spacing * -2}px;
  padding: ${({ theme }) => theme.spacing}px ${({ theme }) => theme.spacing * 2}px;
  ${PageTitle} {
    font-weight: 500;
    font-size: 24px;
  }
`;
const HeaderDefaultExperienceStyle = css`
  margin-bottom: ${({ theme }) => theme.spacing * 4}px;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ theme }) => (theme.name === 'SIT-Embeded' ? HeaderEmbeddedExperienceStyle : HeaderDefaultExperienceStyle)}
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface PageHeaderProps {
  title: string;
  'data-cy'?: string;
  children?: React.ReactNode;
  onBackClick?: () => void;
  hideBackButton?: boolean;
}
export const PageHeader = ({ title, children, onBackClick, hideBackButton, ...props }: PageHeaderProps) => {
  const isBackbuttonHidden = !onBackClick ? true : hideBackButton;

  return (
    <Header data-component="PageHeader">
      <TitleContainer>
        {!isBackbuttonHidden && <BackButton onBack={onBackClick} />}
        <PageTitle {...props}>{title}</PageTitle>
      </TitleContainer>
      {children && <Box>{children}</Box>}
    </Header>
  );
};
