import { WebFeatureFlagNames, WebFeatureFlags } from '@web/launchDarkly/flags';
import { selectDevOverrides } from '@web/redux/selectors';
import { useAppSelector } from '@web/redux/store';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useFlag = <T extends WebFeatureFlagNames>(featureName: T) => {
  const flags = useFlags<WebFeatureFlags>();
  const { featureFlags } = useAppSelector(selectDevOverrides);

  const ldFlag = flags[featureName];

  if (import.meta.env.VITE_APP_SUPPORT_CHANGING_ORIGIN === 'true') {
    const devFlag = featureFlags[featureName];
    return devFlag ?? ldFlag;
  }

  return ldFlag;
};
