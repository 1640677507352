export enum OSPlatforms {
  win = 'win',
  mac = 'mac',
  not_supported = 'not_supported',
}

export const currentOS = (() => {
  const supportedOSs: OSPlatforms[] = [OSPlatforms.mac, OSPlatforms.win];
  return supportedOSs.find((os): os is OSPlatforms => navigator.userAgent.toLowerCase().includes(os)) || OSPlatforms.not_supported;
})();

export const isNotSupported = currentOS === OSPlatforms.not_supported;
