import { i18n } from '@lingui/core';
import { useEffect } from 'react';

const defaultLocale = 'en';

async function dynamicActivate(locale: 'en') {
  const { messages } = await {
    en: () => import('../lingui/en.po'),
  }[locale]();

  i18n.loadAndActivate({ locale, messages });
}

export function useActivateI18n() {
  useEffect(() => {
    dynamicActivate(defaultLocale);
  }, []);
}
