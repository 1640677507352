import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useValidateInvite } from '@sit/client-shared';
import Preview from 'carbon-react/lib/components/preview';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import PublicPage from '../../components/Shared/Public/PublicPage';
import NotFoundPage from '../NotFoundPage';
import InviteForm from './InviteForm';

const Invite = () => {
  const { _ } = useLingui();
  const { inviteToken } = useParams<{ inviteToken: string }>() as { inviteToken: string };
  const {
    data: user,
    isLoading,
    error,
  } = useValidateInvite(inviteToken, {
    requestConfig: {
      skipToast: true,
    },
  });

  const initialFormValues = {
    username: user?.username ?? '',
    companyId: user?.company.intacctCompanyId ?? '',
    password: '',
    passwordConfirm: '',
  };

  if (error?.status === 404) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>{_(msg`Invite | Sage Intelligent Time`)}</title>
      </Helmet>
      <PublicPage>
        {isLoading ? (
          <Preview loading height="700px" width="450px" />
        ) : (
          <InviteForm inviteToken={inviteToken} initialValues={initialFormValues} passwordOnly errorMsg={error?.message} />
        )}
      </PublicPage>
    </>
  );
};

export default Invite;
