import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import CarbonDateInput, { DateInputProps } from 'carbon-react/lib/components/date';
import I18nProvider from 'carbon-react/lib/components/i18n-provider';
import { enUS } from 'date-fns/locale';

const DateInput = ({ ...props }: DateInputProps) => {
  const { _ } = useLingui();

  return (
    <I18nProvider
      locale={{
        date: {
          dateFnsLocale: () => enUS,
          ariaLabels: {
            previousMonthButton: () => _(msg`Previous month`),
            nextMonthButton: () => _(msg`Next month`),
          },
        },
      }}
    >
      <CarbonDateInput {...props} />
    </I18nProvider>
  );
};

export default DateInput;
