import BackButton from '@web/components/Shared/Button/BackButton';
import SummaryCards from '@web/components/Timesheet/SummaryCards';
import { Preview as PreviewTimesheetInformation } from '@web/components/Timesheet/TimesheetInfo';
import { useSummaryData } from '@web/components/Timesheet/hooks';
import Preview from 'carbon-react/lib/components/preview';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useIsStaff } from '../Timesheets/hooks';
import { ActionBarTitle } from './ActionBar/Title';
import { useActionBarTitle } from './ActionBar/useActionBarTitle';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};
  height: 100%;
  padding: 0 ${({ theme }) => theme.space[3]};
  position: relative;
`;

const TopBarPreview = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  height: 64px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: calc(100vh - 144px);
  gap: ${({ theme }) => theme.space[2]};
`;

const ClusterContentPreview = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.palette.slateTint(80)};
  flex: 0 0 379px;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: ${({ theme }) => theme.space[2]};

  & > div {
    height: 300px;
    width: 100%;
    display: flex;
  }
`;

const EditorContentPreview = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  transition: all 0.2s;
  height: 100%;
  gap: ${({ theme }) => theme.space[2]};
`;

const EditorContent = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.palette.slateTint(80)};
  padding: ${({ theme }) => theme.space[3]};
  position: relative;
  transition: all 0.2s;

  & > div {
    flex: 1;
  }
`;

const PreviewComponent = () => {
  const navigate = useNavigate();
  const staff = useIsStaff();
  const items = useSummaryData({
    startDate: '',
    endDate: '',
    glPostDate: '',
    status: 'NO_STATUS',
    showBillable: false,
    billable: 0,
    nonBillable: 0,
    total: 0,
  });

  const onBack = () => {
    navigate(staff ? '/staff-timesheets' : '/timesheets');
  };

  const actionBarTitle = useActionBarTitle();

  return (
    <Root>
      <TopBarPreview>
        <BackButton loading onBack={onBack} />
        <ActionBarTitle title={actionBarTitle} />
      </TopBarPreview>
      <SummaryCards items={items} />
      <PreviewTimesheetInformation />
      <Content>
        {!staff && (
          <ClusterContentPreview>
            <Preview height="300px" loading />
          </ClusterContentPreview>
        )}
        <EditorContentPreview>
          <EditorContent>
            <Preview height="100%" loading />
          </EditorContent>
        </EditorContentPreview>
      </Content>
    </Root>
  );
};

export default PreviewComponent;
