import { useGetApiApprovalsCheck, useGetUser, useGetUserSettings } from '@sit/client-shared';
import { useGetCurrentCompany } from '@web/api/company/use-get-company';
import { config } from '@web/config/env';
import { useIsAuthenticated } from '@web/redux/hooks';
import EntriesApprovalsIndex from '@web/routes/entries-approvals._index';
import ExternalAuth from '@web/routes/external-auth';
import TimeApprovalsIndex from '@web/routes/time-approvals._index';
import TimeCards from '@web/routes/time-cards';
import TimePreferencesIndex from '@web/routes/time-preferences';
import TimeSourcesIndex from '@web/routes/time-sources';
import Verify from '@web/routes/verify';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import useIdentify from '../../analytics/useIdentify';
import PrivateRoute from '../../components/PrivateRoute';
import PublicRoute from '../../components/PublicRoute';
import { entriesApprovalsRoute } from '../EntriesApprovals/helpers/routes';
import ForgotPassword from '../ForgotPassword';
import Homepage from '../Homepage';
import Invite from '../Invite';
import Login from '../Login';
import NotFoundPage from '../NotFoundPage';
import Privacy from '../Privacy';
import ResetPassword from '../ResetPassword';
import { timeApprovalsRoute } from '../TimeApprovals/helpers/routes';
import { timePreferencesRoute } from '../TimePreferences/helpers/routes';
import Timesheet from '../Timesheet';
import Timesheets from '../Timesheets';

interface RedirectToProps {
  path: string;
}

const redirectTo =
  ({ path }: RedirectToProps) =>
  (..._: unknown[]) => {
    const { pathname } = useLocation();
    const staticPathName = pathname.split('/').slice(2).join('/');

    return <Navigate to={`/${path}${staticPathName ? `/${staticPathName}` : ''}`} />;
  };

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  useIdentify(isAuthenticated);

  useGetCurrentCompany();

  useGetUser({
    enabled: isAuthenticated,
  });
  useGetApiApprovalsCheck(
    {},
    {
      enabled: isAuthenticated,
      staleTime: 1000 * 60 * config.VITE_APP_MINUTES_TO_REFRESH_CPTI,
    },
  );

  useGetUserSettings({
    enabled: isAuthenticated,
  });

  return (
    <Routes>
      <Route
        path="/forgot-password"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/invite/:inviteToken"
        element={
          <PublicRoute>
            <Invite />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password/:resetToken"
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />

      <Route path="/external-auth" element={<ExternalAuth />} />
      <Route path="/home" element={<Homepage />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/approvals/*" element={redirectTo({ path: timeApprovalsRoute.substring(1) })()} />
      <Route
        path={`${timeApprovalsRoute}/*`}
        element={
          <PrivateRoute>
            <TimeApprovalsIndex />
          </PrivateRoute>
        }
      />
      <Route
        path={`${entriesApprovalsRoute}/*`}
        element={
          <PrivateRoute>
            <EntriesApprovalsIndex />
          </PrivateRoute>
        }
      />
      <Route
        path={`${timePreferencesRoute}/*`}
        element={
          <PrivateRoute>
            <TimePreferencesIndex />
          </PrivateRoute>
        }
      />
      <Route
        path="/data-sources/*"
        element={
          <PrivateRoute>
            <TimeSourcesIndex />
          </PrivateRoute>
        }
      />
      <Route
        path="/timesheet/:id/*"
        element={
          <PrivateRoute paramsToValidate={{ id: 'uuid' }}>
            <Timesheet />
          </PrivateRoute>
        }
      />
      <Route
        path="/timesheets"
        element={
          <PrivateRoute>
            <Timesheets />
          </PrivateRoute>
        }
      />
      <Route
        path="/time-cards"
        element={
          <PrivateRoute>
            <TimeCards />
          </PrivateRoute>
        }
      />
      <Route
        path="/staff-timesheets"
        element={
          <PrivateRoute>
            <Timesheets />
          </PrivateRoute>
        }
      />
      <Route path="/settings/*" element={redirectTo({ path: 'time-preferences' })()} />

      <Route path="/" element={<Navigate to="/timesheets" />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
