import { Trans } from '@lingui/macro';
import Message from 'carbon-react/lib/components/message';

const ErrorMessage = ({ error }: { error: unknown }) => {
  return (
    <Message open variant="error">
      <Trans>Something went wrong. Please try again.</Trans>
      {import.meta.env.NODE_ENV !== 'production' && <pre>{JSON.stringify(error, null, 2)}</pre>}
    </Message>
  );
};
export default ErrorMessage;
