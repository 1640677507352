import { GetApiTasksError, GetApiTasksResponse, GetTasksInfiniteVariables, useGetTasks as useGetApiTasks } from '@sit/client-shared';
import { QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { includeIntacctId, sortObjectsByNameAsc } from '@web/helpers/utils';
import { useMemo } from 'react';
import { useShowIntacctId } from '../show-intacct-id';

export function useGetTasks(
  variables: GetTasksInfiniteVariables,
  options?:
    | Omit<
        UseInfiniteQueryOptions<GetApiTasksResponse, GetApiTasksError, GetApiTasksResponse, GetApiTasksResponse, QueryKey>,
        'queryKey' | 'queryFn'
      >
    | undefined,
) {
  const showIntacctId = useShowIntacctId();
  const { data: tasksData } = useGetApiTasks(variables, options);
  const data = useMemo(() => {
    if (!tasksData) return [];
    return (showIntacctId ? tasksData.map((d) => includeIntacctId(d)) : [...tasksData]).sort(sortObjectsByNameAsc) as GetApiTasksResponse;
  }, [showIntacctId, tasksData]);
  return { ...tasksData, data };
}
