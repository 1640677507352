import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { TimesheetRow } from '@sit/api-types';
import { type Cluster, type ClusterNode } from '@sit/client-shared';
import { ActivityItemType } from '@web/constants/dnd';
import { logout } from './authentication-slice';

export type SelectedValues = {
  clientId?: string;
  projectId?: string;
};

export type ClusterAssignModalStore = {
  clusterId: string | undefined;
  item: {
    data?: {
      node: ClusterNode | null;
      cluster: Cluster | null;
    };
    row?: TimesheetRow;
    type?: ActivityItemType;
  };
  visible: boolean;
  values: null | {
    client?: string;
    project?: string;
    task?: string;
    item?: string;
    timeType?: string;
    costType?: string;
    employeePosition?: string;
    laborClass?: string;
    laborShift?: string;
    laborUnion?: string;
    department?: string;
    location?: string;
    billable?: boolean;
    rowId?: string;
  };
  selectedValues: SelectedValues;
};

const initialState: ClusterAssignModalStore = {
  clusterId: undefined,
  item: {},
  visible: false,
  values: null,
  selectedValues: {},
};

const clusterAssignModalSlice = createSlice({
  name: 'clusterAssignModal',
  initialState,
  reducers: {
    showClusterAssignModal: (
      state,
      action: PayloadAction<Pick<ClusterAssignModalStore, 'clusterId' | 'item'> & Partial<Pick<ClusterAssignModalStore, 'values'>>>,
    ) => {
      state.visible = true;
      state.clusterId = action.payload.clusterId;
      state.item = action.payload.item;
      state.values = action.payload.values || null;
      return state;
    },
    hideClusterAssignModal: () => initialState,
    setClusterAssignModalSelectedValues: (state, action: PayloadAction<SelectedValues>) => {
      Object.assign(state.selectedValues, action.payload);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.type, () => initialState);
  },
});

export const { showClusterAssignModal, hideClusterAssignModal, setClusterAssignModalSelectedValues } = clusterAssignModalSlice.actions;
export default clusterAssignModalSlice.reducer;
