import { NotificationGraphQl } from '@sit/client-shared';
import { useContext } from 'react';
import { NotificationHandlerContext } from '../NotificationProvider';

const useNotifications = (): NotificationGraphQl[] => {
  const { notifications } = useContext(NotificationHandlerContext);

  return notifications;
};

export default useNotifications;
