import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { loadingChunkRetry } from '@sit/client-shared';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import ErrorBoundary from '../../components/ErrorBoundary';
import DashboardContainer from '../DashboardContainer';
import PreviewComponent from './PreviewComponent';

const Homepage = lazy(() => loadingChunkRetry(() => import('./Homepage')));

const HomepageContainer = () => {
  const { _ } = useLingui();
  return (
    <div className="homepage-container">
      <Helmet>
        <title>{_(msg`Home | Sage Intelligent Time`)}</title>
      </Helmet>
      <ErrorBoundary>
        <Suspense fallback={<PreviewComponent />}>
          <DashboardContainer showSecondaryNav>
            <Homepage />
          </DashboardContainer>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default HomepageContainer;
