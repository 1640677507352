import { PillTextType, StatusPillColors } from '@web/components/StatusPill/hooks/usePillLocales';

type PillsInfo = {
  pillText: PillTextType;
  pillType: StatusPillColors;
};
const useGetPillInfo = (status: string | undefined | null): PillsInfo => {
  const string = `${status}`.toLowerCase();
  switch (string) {
    case 'draft':
      return {
        pillText: 'Draft',
        pillType: 'default',
      };
    case 'submitted':
      return {
        pillText: 'Submitted',
        pillType: 'info',
      };
    case 'declined':
      return {
        pillText: 'Declined',
        pillType: 'error',
      };
    case 'partially_approved':
      return {
        pillText: 'PartiallyApproved',
        pillType: 'warning',
      };
    case 'partially_declined':
      return {
        pillText: 'PartiallyDeclined',
        pillType: 'warning',
      };
    case 'approved':
      return {
        pillText: 'Approved',
        pillType: 'success',
      };
    case 'invoiced':
      return {
        pillText: 'Invoiced',
        pillType: 'success',
      };
    case 'processing':
      return {
        pillText: 'Processing',
        pillType: 'default',
      };
    default:
      return {
        pillText: 'NoInfo',
        pillType: 'info',
      };
  }
};

export { useGetPillInfo };
