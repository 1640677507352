import { Trans } from '@lingui/macro';
import { TimesheetType } from '@sit/client-shared';
import moment from 'moment';
import styled from 'styled-components';

const TimesheetItem = styled.div`
  white-space: nowrap;
`;

const toDateString = (date: string) => moment(date.substring(0, 10)).format('MM/DD/YYYY');

interface TimesheetListItemProps {
  timesheet: Pick<TimesheetType, 'startDate' | 'endDate' | 'id' | 'description'>;
}

export const TimesheetListItem = ({ timesheet }: TimesheetListItemProps) => {
  const { startDate, endDate, id, description } = timesheet;
  const dates = `${toDateString(startDate)} - ${toDateString(endDate)}`;

  return (
    <TimesheetItem key={id}>
      <Trans>
        Dates: <strong>{dates}</strong> Description: <strong>{description || 'N/A'}</strong>
      </Trans>
    </TimesheetItem>
  );
};
