import { TimesheetType } from '@sit/client-shared';
import { useEffect, useState } from 'react';
import apiClient from '../helpers/api';

export const useTimesheet = (timesheetId: string) => {
  const [timesheet, setTimesheet] = useState<TimesheetType | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      try {
        const timesheetData = await apiClient.get<TimesheetType>({
          path: `/timesheets/${timesheetId}`,
          skipToast: true,
        });

        setTimesheet(timesheetData);
      } catch (error_) {
        setError(error_ as Error);
      } finally {
        setLoading(false);
      }
    })();
  }, [timesheetId]);

  return {
    timesheet,
    loading,
    error,
  };
};
