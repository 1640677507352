import { GetApiTimesheetsResponse } from '@sit/client-shared';
import { useGetCurrentCompany } from '@web/api/company/use-get-company';
import { usePostTimesheetSubmitAsync } from '@web/api/timesheet/use-post-timesheet-submit';
import { useSendPrintToIntacct } from '@web/api/timesheet/use-print-timesheet';
import { useLocationQuery } from '@web/helpers/hooks';
import { useCurrentEntity } from '@web/hooks/use-current-entity';
import { resetAutofill } from '@web/redux/Autofill/autofill-slice';
import { useAppDispatch } from '@web/redux/store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTimesheetModals } from '.';
import { Actions } from '../components/TimesheetsTable/TableRowActions';

export const useTimesheetsPage = () => {
  const dispatch = useAppDispatch();
  const query = useLocationQuery();
  const { openNewTimesheetModal, openDuplicateTimesheetModal, openBulkDeleteTimesheetsModal } = useTimesheetModals();
  const navigate = useNavigate();
  const { mutate: submitTimesheetAsync } = usePostTimesheetSubmitAsync();
  const sendPrintToIntacct = useSendPrintToIntacct();
  const { data: company } = useGetCurrentCompany();
  const currentEntity = useCurrentEntity();

  const openCreateTimesheetModal = query.get('create_new_timesheet') === 'true';
  const managedAtEntityLevel = !!company?.managedAtEntityLevel;

  const isTopLevel = !!currentEntity && currentEntity.intacctEntityId == null;
  const disableCreateTimesheet = isTopLevel && managedAtEntityLevel;

  if (!managedAtEntityLevel && openCreateTimesheetModal) {
    openNewTimesheetModal();
    navigate({ search: '' }, { replace: true });
  }

  useEffect(() => {
    /**
     * @legacy
     * Reset the timesheet state when the user navigates away from the timesheets page
     */
    dispatch(resetAutofill());
    // TODO: figure out what to do here...
    // dispatch(resetTimesheetEntriesState());
    // dispatch(resetTimesheetRowsState());
  }, [dispatch]);

  const handleAction = (action: Actions, timesheet: Pick<GetApiTimesheetsResponse[number], 'id'>) => {
    switch (action) {
      case 'submit':
        submitTimesheetAsync({ pathParams: { id: timesheet.id } });
        break;
      case 'duplicate':
        openDuplicateTimesheetModal(timesheet.id);
        break;
      case 'print-to-intacct':
        sendPrintToIntacct({ timesheetId: timesheet.id });
        break;
      case 'delete':
        openBulkDeleteTimesheetsModal([timesheet.id]);
        break;
    }
  };

  return {
    managedAtEntityLevel,
    handleAction,
    disableCreateTimesheet,
  };
};
