import { useGetApiEmployees, type GetApiEmployeesError, type GetApiEmployeesResponse } from '@sit/client-shared';
import type { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useIsStaff } from '@web/containers/Timesheets/hooks';
import { includeIntacctId, sortObjectsByNameAscUnsafe } from '@web/helpers/utils';
import { useMemo } from 'react';
import { useShowIntacctId } from '../show-intacct-id';

export function useGetEmployees(
  options?:
    | Omit<
        UseQueryOptions<GetApiEmployeesResponse, GetApiEmployeesError, GetApiEmployeesResponse, QueryKey>,
        'queryKey' | 'queryFn' | 'initialData'
      >
    | undefined,
) {
  const staff = useIsStaff();
  const result = useGetApiEmployees(
    {},
    {
      ...options,
      enabled: staff && options?.enabled !== false,
    },
  );
  const showIntacctId = useShowIntacctId();
  const emloyeesData = result.data?.employees;
  const employees = useMemo(() => {
    if (!emloyeesData) return undefined;
    return (showIntacctId ? emloyeesData.map((d) => includeIntacctId(d, { fieldName: 'employeeId' })) : [...emloyeesData]).sort(
      sortObjectsByNameAscUnsafe,
    );
  }, [emloyeesData, showIntacctId]);
  return { ...result, data: { employees } };
}
