import { combineReducers } from '@reduxjs/toolkit';
import user from './authentication-slice';
import autofill from './Autofill/autofill-slice';
import clusterAssignModal from './cluster-assign-modal-slice';
import development from './Development/slice';
import generalReducer from './general-slice';
import localReducer from './local-slice';
import modalReducer from './modal-slice';
import { reducer as timesheetStatus } from './timesheet-status-slice';
import timesheets from './timesheets-slice';

const rootReducer = combineReducers({
  autofill,
  clusterAssignModal,
  development,
  general: generalReducer,
  local: localReducer,
  modal: modalReducer,
  timesheets,
  timesheetStatus,
  user,
});

export default rootReducer;
