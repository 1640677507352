import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Form from 'carbon-react/lib/components/form';
import Message from 'carbon-react/lib/components/message';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PublicButton from '../../components/Shared/Public/PublicButton';
import PublicForm from '../../components/Shared/Public/PublicForm';
import PublicPassword from '../../components/Shared/Public/PublicPassword';
import PublicTextBox from '../../components/Shared/Public/PublicTextBox';

const ErrorWrapper = styled.div`
  margin-top: 20px;
`;

export interface LoginFormValues {
  company: string;
  username: string;
  password: string;
}

interface LoginFormProps {
  errorMsg?: string;
  loading: boolean | undefined;
  initialValues?: LoginFormValues;
  onSubmit: (values: LoginFormValues) => void;
}

const INITIAL_VALUES: LoginFormValues = {
  company: '',
  username: '',
  password: '',
};

const useValidate = () => {
  const { _ } = useLingui();
  return useCallback(
    (values: LoginFormValues) => {
      const errors: Partial<LoginFormValues> = {};

      if (!values.company) {
        errors.company = _(msg`Required`);
      }

      if (!values.username) {
        errors.username = _(msg`Required`);
      }

      if (!values.password) {
        errors.password = _(msg`Required`);
      }

      return errors;
    },
    [_],
  );
};

const LoginForm = ({ errorMsg, onSubmit, loading, initialValues = INITIAL_VALUES }: LoginFormProps) => {
  const { _ } = useLingui();
  const validate = useValidate();
  const { handleSubmit, values, errors, handleChange } = useFormik<LoginFormValues>({
    initialValues,
    validate,
    isInitialValid: false,
    validateOnChange: false,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const { company, username, password } = values;

  return (
    <PublicForm
      title={_(msg`Intelligent Time`)}
      footerComponent={
        <Link data-pendo-id="forgot-password" to="/forgot-password" data-testid="forgot-password">
          <Trans>Forgot your password?</Trans>
        </Link>
      }
    >
      <Form
        saveButton={
          <PublicButton data-pendo-id="login" buttonType="primary" label={_(msg`Log in`)} loading={loading} key="login" type="submit" />
        }
        onSubmit={handleSubmit}
      >
        <PublicTextBox
          id="company"
          label={_(msg`Intacct company ID`)}
          name="company"
          onChange={handleChange}
          value={company}
          error={errors?.company}
          data-testid="company-id"
        />
        <PublicTextBox
          id="username"
          label={_(msg`Intacct user ID`)}
          name="username"
          onChange={handleChange}
          value={username}
          error={errors?.username}
          data-testid="username"
        />
        <PublicPassword
          id="password"
          label={_(msg`Password`)}
          name="password"
          onChange={handleChange}
          value={password}
          data-testid="password"
          error={errors?.password}
        />

        {errorMsg && (
          <ErrorWrapper data-testid="Login-error-msg">
            <Message variant="error">{errorMsg}</Message>
          </ErrorWrapper>
        )}
      </Form>
    </PublicForm>
  );
};

export default LoginForm;
