import { useCanListMyTimesheets, useGetApiApprovalsCheck } from '@sit/client-shared';
import { useLogout } from '@web/api/auth/use-logout';
import { useIsAuthenticated } from '@web/redux/hooks';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BuildType } from '../../redux/Development';
import { selectBuildType, selectDevOverrides } from '../../redux/selectors';
import { useAppSelector } from '../../redux/store';
import { entriesApprovalsRoute } from '../EntriesApprovals/helpers/routes';
import { timeApprovalsRoute } from '../TimeApprovals/helpers/routes';
import { ApplicationNav } from './ApplicationNav';
import { ProfileMenuActions } from './ApplicationNav/ProfileMenu';
import { DashboardFooter } from './DashboardFooter';
import { DebugVersion } from './DebugVersion';
import SecondaryNav, { SecondaryNavTabs } from './SecondaryNav';

const Root = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
`;
const NavContainer = styled.div``;
const PageBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
const Content = styled.div`
  flex: 1;
`;

const EmbeddedModeDebugContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.aboveAll};
`;

const useDashboard = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { data: getApprovalsCheckResponse } = useGetApiApprovalsCheck(
    {},
    {
      enabled: isAuthenticated,
      staleTime: 1000 * 60 * 5,
    },
  );
  const showApprovalsTab = getApprovalsCheckResponse?.hasApproval ?? false;
  const buildType = useAppSelector(selectBuildType);
  const { dashboardVisible } = useAppSelector(selectDevOverrides);
  const logout = useLogout();

  const hideDashboard = !dashboardVisible && buildType === BuildType.embedded;

  // biome-ignore lint/correctness/useExhaustiveDependencies: navigate
  const handleApplicationNavAction = useCallback(
    (action: ProfileMenuActions) => {
      switch (action) {
        case 'login':
          return navigate('/login');
        case 'logout':
          return logout();
        case 'settings':
          return navigate('/time-preferences');
      }
    },
    [logout],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: navigate
  const handleSecondaryNavTabChange = useCallback((action: SecondaryNavTabs) => {
    switch (action) {
      case 'timesheets':
        return navigate('/timesheets');
      case 'staff-timesheets':
        return navigate('/staff-timesheets');
      case 'approvals':
        return navigate('/approvals');
      case 'data-sources':
        return navigate('/data-sources');
      case 'entries-approvals':
        return navigate(entriesApprovalsRoute);
      case 'time-approvals':
        return navigate(timeApprovalsRoute);
    }
  }, []);

  return {
    hideDashboard,
    handleApplicationNavAction,
    handleSecondaryNavTabChange,
    isAuthenticated,
    showApprovalsTab,
  };
};

interface DashboardContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  showSecondaryNav?: boolean;
}

const DashboardContainer = ({ showSecondaryNav, children, ...props }: DashboardContainerProps) => {
  const { hideDashboard, handleApplicationNavAction, handleSecondaryNavTabChange, isAuthenticated, showApprovalsTab } = useDashboard();
  const canListMyTimesheets = useCanListMyTimesheets();

  return (
    <Root>
      {!hideDashboard && (
        <NavContainer data-testid="navigation-bars">
          <ApplicationNav isAuthenticated={isAuthenticated} onAction={handleApplicationNavAction} />
          {showSecondaryNav && isAuthenticated && (
            <SecondaryNav
              onTabChange={handleSecondaryNavTabChange}
              hideMyTimesheetsTab={!canListMyTimesheets}
              hideApprovalsTab={!showApprovalsTab}
            />
          )}
        </NavContainer>
      )}
      {hideDashboard && (
        <EmbeddedModeDebugContainer>
          <DebugVersion />
        </EmbeddedModeDebugContainer>
      )}
      <PageBody>
        <Content {...props}>{children}</Content>
      </PageBody>
      {!hideDashboard && <DashboardFooter />}
    </Root>
  );
};

export default DashboardContainer;
