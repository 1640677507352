const objectHasProperty = (obj: unknown, property: string): boolean => {
  if (typeof obj !== 'object' || typeof property !== 'string' || !property) return false;
  return Object.prototype.hasOwnProperty.call(obj, property);
};

/**
 * Checks if an Object has a nested property.
 *
 * @param obj The object to check.
 * @param path Exact path of the property to be tested
 * @example
 *    const objToTest = {
 *      a: {
 *        b: {
 *          c: {
 *            "d": "hello"
 *          }
 *        }
 *      }
 *    }
 *
 *    objectHasNestedProperty(objToTest, ['a', 'b', 'c', 'd']) => TRUE
 *    objectHasNestedProperty(objToTest, ['a', 'b', 'c', 'foo']) => FALSE
 *    objectHasNestedProperty(objToTest, ['a', 'b']) => TRUE
 *    objectHasNestedProperty(objToTest, ['a', 'd']) => FALSE
 *
 */
const objectHasNestedProperty = (obj: Record<string, any>, path: string[]): boolean => {
  let prev = obj;
  return path.every((property) => {
    if (objectHasProperty(prev, property)) {
      prev = prev[property];
      return true;
    }
    return false;
  });
};

export { objectHasProperty, objectHasNestedProperty };
