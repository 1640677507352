import { getCompanyFromSessionStorage } from '@sit/client-shared';

export type GetCompanyFromWebSessionStorageReturn =
  | {
      id: string;
    }
  | undefined;

// Session storage is synchronous on the web (async on mobile).
// Since the web is synchronous, we can ignore the Promise return type.
export const getCompanyFromWebSessionStorage = () => getCompanyFromSessionStorage() as GetCompanyFromWebSessionStorageReturn;
