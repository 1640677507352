/// <reference types="@types/pendo-io-browser" />

/**
 *  We use Pendo.io for analytics. Read more here:
 *  https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-Guide-To-Installing-the-Pendo-Snippet
 *  Because we add the Pendo snippet on the index.html, we can access the `pendo` variable directly without importing it
 */

const eventNamePrefix = 'SIT_WEB';

/**
 *  Method to initialize the Pendo snippet.
 */
export const initialize = (initOptions: pendo.InitOptions) => {
  if (!initOptions.visitor?.id) return;
  // user is not logged in
  pendo?.initialize?.(initOptions);
};

/**
 *  Method to Track specific events
 */
export const track = (event: string, metadata?: Record<string, any>) => {
  pendo?.track?.(`${eventNamePrefix}_${event}`, metadata);
};

/**
 *  Method to identify the user with Pendo.
 *  Currently NOT used
 */
// export const identify = (visitorId: string, accountId?: string) => {
//   if (!visitorId) return;
//   // user is not logged in
//   pendo?.identify?.(visitorId, accountId);
// };
