import { useLingui } from '@lingui/react';
import { useGetUser } from '@sit/client-shared';
import { useGetCurrentCompany } from '@web/api/company/use-get-company';
import { usePostTimesheet } from '@web/api/timesheet/use-post-timesheet';
import moment from 'moment';
import { Navigate } from 'react-router-dom';
import { useLocationQuery } from '../../../helpers/hooks';
import toastr from '../../../helpers/toastr';
import { getTimesheetsRoute } from '../helpers/routes';
import { getEndDate, getMonthRemainingHalf, getStartOfWeek } from '../helpers/timesheets';
import { useIsStaff, useTimesheetModals } from '../hooks';
import { CreateTimesheetModal, type FormData } from './CreateTimesheetModal';
import { mustHaveEmployee, noEditPermissions } from './messages';

const TIME_FORMAT = 'YYYY-MM-DD';

const defaultData = {
  weekStart: 1,
  timesheetDuration: 7,
} as const;

const useDefaultData = (query: URLSearchParams) => {
  const { data: company } = useGetCurrentCompany();
  const { weekStart, timesheetDuration } = company || defaultData;

  const queryStartDate = query.get('start_date');
  const queryEndDate = query.get('end_date');

  if (queryStartDate && queryEndDate) {
    return {
      startDate: moment(queryStartDate, TIME_FORMAT),
      endDate: moment(queryEndDate, TIME_FORMAT),
    };
  }

  if (timesheetDuration === 15) {
    return getMonthRemainingHalf();
  }
  const startDate = timesheetDuration === 1 ? moment().startOf('day') : getStartOfWeek(weekStart);

  return {
    startDate,
    endDate: getEndDate(timesheetDuration, startDate),
  };
};

export const NewTimesheet = () => {
  const { _ } = useLingui();
  const query = useLocationQuery();
  const { startDate, endDate } = useDefaultData(query);
  const { data: userData } = useGetUser();
  const employeeId = userData?.employeeId;
  const { closeModal } = useTimesheetModals();
  const staff = useIsStaff();

  const { mutate: createTimesheet } = usePostTimesheet();

  const handleSubmit = ({ startDate, endDate, description, employeeId }: FormData) => {
    if (!employeeId) {
      toastr.warning(_(mustHaveEmployee));
      return;
    }
    createTimesheet({
      body: {
        description,
        endDate: endDate.format(TIME_FORMAT),
        startDate: startDate.format(TIME_FORMAT),
        externalEmployeeId: employeeId,
      },
      queryParams: {
        staff: staff ? 'true' : 'false',
      },
    });
  };

  if (!staff && userData && (!userData.canEditTimesheets || !userData.canCreateTimesheet)) {
    const messageDescriptor = !userData.canEditTimesheets ? noEditPermissions : mustHaveEmployee;
    toastr.warning(_(messageDescriptor));
    return <Navigate to={getTimesheetsRoute} />;
  }

  return (
    <CreateTimesheetModal
      staff={staff}
      data={{
        startDate,
        endDate,
        description: '',
        employeeId: employeeId ?? undefined,
      }}
      onSubmit={handleSubmit}
      onClose={closeModal}
    />
  );
};
