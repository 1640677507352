import { ProviderConfig } from 'launchdarkly-react-client-sdk/lib/types';

import { loadUser } from '../redux/localStorage';

function buildLaunchDarklyConfig({ clientSideID, disabled = false }: { clientSideID: string; disabled: boolean }): ProviderConfig {
  const enabled = !disabled;
  const config: ProviderConfig = {
    clientSideID: clientSideID,
    options: {
      fetchGoals: enabled,
      streaming: enabled,
      sendEvents: enabled,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  };
  return config;
}

export function buildLaunchDarklyConfigWithUser() {
  const localStorageUser = loadUser();
  const disabled = import.meta.env.VITE_APP_LAUNCH_DARKLY_DISABLED === 'true';
  const clientSideID = `${import.meta.env.VITE_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID}`;

  const ldConfig = buildLaunchDarklyConfig({ clientSideID, disabled });
  if (localStorageUser?.data?.id && localStorageUser?.data?.launchDarklyPayload) {
    ldConfig.user = {
      key: localStorageUser.data.id,
      custom: localStorageUser.data.launchDarklyPayload,
    };
  } else {
    ldConfig.user = {
      key: 'ANONYMOUS_USER',
      anonymous: true,
    };
  }

  return ldConfig;
}
